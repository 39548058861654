
import React, { Component } from "react"
import { createTeam, getTeams, updateTeam } from "../../actions/api"
import NewTeam from "../Organizations/NewTeam"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class NewTeamContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      text: ""
    }
  }

  handleOnBack = () => this.props.history.goBack()
  navigateSelectTeam = () => this.props.history.push("/teams/select")

  onChange = (evt) => {
    this.setState({ text: evt.target.value })
  }

  create = async () => {
    try {
      const team = {
        name: this.state.text,
        members: [{ user: this.props.user, isAdmin: true, accepted: true }]
      }
      const resp = await this.props.createTeam(team)
      if (resp.error) alert("Error " + resp.error)
      else {
        this.props.getTeams()
        this.setState({ text: "" })
      }
    } catch (error) {
      alert("Error: " + error)
    }
  }

  render() {
    return (
      <NewTeam
        text={this.state.text}
        user={this.props.user}
        onSubmitSuccess={this.navigateSelectTeam}
        onBack={this.handleOnBack}
        onChange={this.onChange}
        createTeam={this.create}
        updateTeam={this.props.updateTeam}
      />
    )
  }

}

const mapDispatchToProps = {
  createTeam,
  updateTeam,
  getTeams
}

const mapStateToProps = (state) => ({
  user: state.users.activeUser
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewTeamContainer)
)