import { Alert, Button, Col, Grid, Row } from "react-bootstrap"
import React, { Component } from "react"
import { getCalendars, getTeams, setCurrentCalendar, updateCalendar } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import ModalAddCalendar from "./ModalAddCalendar"
import ModalUpdateCalendar from "./ModalUpdateCalendar"
import UpdateCalendar from "./UpdateCalendar"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class CalendarsListView extends Component {

  constructor(props) {
    super(props)

    this.handleDayClick = this.handleDayClick.bind(this)
    this.state = {
      selectedDay: undefined,
      showModalUpdate: false,
      showModalAdd: false,
      currentCalendar: null
    }

    this.modifiers = {
      highlighted: []
    }
  }

  handleDayClick(day, { selected, disabled }) {
    if (disabled) {
      return
    }
    if (selected) {
      this.setState({ selectedDay: undefined })
      return
    }
    this.setState({ selectedDay: day })
  }

  holidaysToDates(holidays) {
    this.modifiers = {
      highlighted: []
    }
    const today = new Date()
    const year = today.getFullYear()
    holidays.map(h => {
      const d = new Date(year, h.month - 1, h.day)
      this.modifiers.highlighted.push(d)
    })
  }

  openModalUpdate = () => {
    this.setState({ showModalUpdate: true })
  }

  closeModalUpdate = () => {
    this.setState({ showModalUpdate: false })
  }

  openModalAdd = () => {
    this.setState({ showModalAdd: true })
  }

  closeModalAdd = () => {
    this.setState({ showModalAdd: false })
  }

  render() {
    const { showModalUpdate, showModalAdd, currentCalendar } = this.state
    const today = new Date()
    const year = today.getFullYear()
    return (
      <div className="page dashboard-page" id="calendarsPage">

        <ComponentHeader />
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">
            <div className="title-container">
              <h2 className="section-title mr-auto">CALENDARS</h2>
              <Button className="icon-button" disabled onClick={() => this.props.history.push("/calendars/list")}>
                <i className="fa fa-list" />
              </Button>
              <Button className="icon-button" onClick={() => this.props.history.push("/calendars")}>
                <i className="fa fa-th" />
              </Button>
            </div>
            <Grid>
              <Row>

                {(this.props.calendars !== null && this.props.calendars !== undefined && this.props.calendars.length !== 0) ? //eslint-disable-line
                  this.props.calendars.map(calendar => {
                    return (
                      <Col xs={12} md={6} lg={4} key={calendar._id}>
                        <div className="calendar-container" key={calendar._id}>
                          {this.holidaysToDates(calendar.holidays)}

                          <div className="calendar-header no-border">
                            <div className="calendar-name">{calendar.name}</div>
                            <Button
                              className="icon-button"
                              onClick={() => {
                                this.setState({ currentCalendar: calendar }, () => {
                                  this.openModalUpdate()
                                })
                              }}
                            >
                              <i className="fa fa-edit" />
                            </Button>
                            <Button
                              className="icon-button"
                            ><i className="fa fa-trash"></i>
                            </Button>
                          </div>
                          <div className="holidays-list-container">
                            <span className="holidays-title">Días de vacaciones</span>
                            <ul className="holidays-list">
                              {calendar.holidays.map(holiday => {
                                return (
                                  <li key={holiday._id}>
                                    <span className="holidays-date">{holiday.day}/{holiday.month}/{year}</span>
                                    <span className="holidays-name">Nombre</span>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </Col>
                    )
                  })
                  :
                  <Alert bsStyle="warning" className="full-width">
                    <i className="fa fa-exclamation-triangle" />
                    <div className="alert-content">
                      <h4>No hay calendarios para este equipo</h4>
                      <hr />
                    </div>
                  </Alert>
                }
                <Col xs={12} md={6} lg={4}>
                  <Button className="button-card" bsStyle="primary" onClick={this.openModalAdd}>
                    <span>Añadir calendario</span>
                    <i className="fa fa-plus-circle"></i>
                  </Button>
                  {/* <span className="separator" />
                  <Button bsStyle="primary" className="cancel" onClick={() => this.props.history.goBack()}>Cancelar</Button>*/}
                </Col>
              </Row>
            </Grid>
            {/*showModalUpdate ?
              < ModalUpdateCalendar
              isOpen={showModalUpdate}
              close={this.closeModalUpdate}
            />
            :
            []
            */}

            {currentCalendar &&
              <ModalUpdateCalendar
                isOpen={showModalUpdate}
                close={this.closeModalUpdate}
                currentCalendar={currentCalendar}
              />
            }

            <ModalAddCalendar
              isOpen={showModalAdd}
              close={this.closeModalAdd}
            />
          </div>
        </div>
      </div>

    )
  }

}

const mapStateToProps = (state, props) => {
  //eslint-disable-line
  return {
    calendars: state.teams.length > 0 ? state.teams.find(team => team._id === state.users.currentTeam._id).calendars : [],//eslint-disable-line
    team: state.users.currentTeam,
    currentCalendar: state.users.currentCalendar
  }
}

const mapDispatchToProps = {
  getCalendars,
  setCurrentCalendar,
  updateCalendar,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarsListView)) //eslint-disable-line