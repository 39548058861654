import attachments from "./attachments";
import users from "./users";
import teams from "./teams";
import sessions from "./sessions";
import projects from "./projects";
import calendars from "./calendars";
import workzones from "./workzones";
import reports from "./reports";
import notifications from "./notifications";
import detections from "./detections";
import auth from "./auth"

export default {
  auth,
  users,
  teams,
  sessions,
  projects,
  calendars,
  workzones,
  attachments,
  reports,
  notifications,
  detections,
};
