import { apiUploadAttachment, apiDeleteAttachment } from "../actions/api";

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiUploadAttachment.types.success: {
    return { ...state, ...payload }
  }
  case apiDeleteAttachment.types.success: {
    return { ...state, ...payload }
  }
  default:
    return state
  }
}
