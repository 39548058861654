import { Button, Col, Form, FormControl, FormGroup, Grid, PageHeader, Row } from "react-bootstrap"
import React from "react"
import { apiResetPass } from "../../actions/api"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import logoProdit from "../../images/logoProdit.svg"
// creo que todo esto está mal, al menos las llamadas a la api
const ResetPass = props => (

  <Grid className="login-page">
    <Row className="center-content">
      <Col xs={12} md={10} lg={5}>
        <div className="logo-area">
          <h1><img className="logo-img" src={logoProdit} /></h1>
        </div>
        <PageHeader>TEAMS, PROJECTS and SESSIONS MANAGEMENT</PageHeader>
      </Col>
    </Row>
    <Row className="center-content">
      <Col xs={12} md={10} lg={5}>
        <div className="card enterform">
          <div className="card-header">
            <div className="card-title">
              <div className="brackets">
                <span className="top-left"></span>
                <span className="top-right"></span>
                <span className="bottom-left"></span>
                <span className="bottom-right"></span>
              </div>
              <span className="card-title-name">RESET<b> PASSWORD</b></span>
            </div>
          </div>
          <div className="card-body">
            <Form>
              <FormGroup className="icon-input">
                <i className="fa fa-envelope"></i>
                <FormControl className="email" name="email" placeholder="Enter e-mail" type="text" />
              </FormGroup>
              <FormGroup className="icon-input">
                <i className="fa fa-lock"></i>
                <FormControl className="password" name="password" placeholder="Enter Password" type="password" />
              </FormGroup>
            </Form>
            <div className="flex-row">
              <Button bsStyle="primary" className="cancel" onClick={() => props.history.goBack()}>Cancel</Button>
              <span className="separator"/>
              <Button bsStyle="primary" onClick={() => console.log("prueba")}> Reset Password </Button>
            </div>
          </div>
          <div className="gradient-bottom" />
        </div>
      </Col>
    </Row>
  </Grid>
)

const mapDispatchToProps = {
  apiResetPass
}

const mapStateToProps = (state, props) => ({  // eslint-disable-line
  email: state.users.email
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPass)) // eslint-disable-line
