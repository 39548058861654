import { apiAuth } from "../actions/api"

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiAuth.types.success: {
    return { ...state, ...payload }
  }
  default:
    return state
  }
}
