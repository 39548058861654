import { Grid, Row, Col, PageHeader, Form, FormGroup, FormControl, Button} from "react-bootstrap"
import React, { Component } from "react"
import { auth, getUser, signUp } from "../../actions/api"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import logoProdit from "../../images/logoProdit.svg"

var sha512 = require("js-sha512").sha512

class Register extends Component {

  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    }
  }

  navigateLogin = () => { this.props.history.push("/") }
  onChangeFirstName = evt => { this.setState({ firstName: evt }) }
  onChangeLastName = evt => { this.setState({ lastName: evt }) }
  onChangeEmail = evt => { this.setState({ email: evt }) }
  onChangePassword = evt => { this.setState({ password: evt }) }
  onChangeCheckPassword = evt => { this.setState({ checkPassword: evt }) } //eslint-disable-line

  onSubmit = async () => {
    var firstName = document.getElementsByClassName("form-control firstname")["firstname"].value
    var lastName = document.getElementsByClassName("form-control lastname")["lastname"].value
    var email = document.getElementsByClassName("form-control email")["email"].value
    var pass = document.getElementsByClassName("form-control password")["password"].value
    var checkPass = document.getElementsByClassName("form-control checkpassword")["checkpassword"].value
    pass = sha512(pass)
    checkPass = sha512(checkPass)
    if (pass === checkPass) {
      try {
        let resp = await this.props.signUp(firstName, lastName, email, pass)
        if (resp.error) return alert("Este usuario ya existe")
        let resp2 = await this.props.auth(email, pass)
        if (resp2.error) alert("Error " + resp2.error)
        else {
          this.props.getUser(resp2.payload.id)
          this.props.history.goBack()
        }
      } catch (error) {
        console.log("Error: ", error) // eslint-disable-line
      }
    } else alert("Las contraseñas no coinciden ")
  };

  render() {
    return (
      <Grid className="login-page">
        <Row className="center-content">
          <Col md={10} lg={5}>
            <div className="logo-area">
              <h1><img className="logo-img" src={logoProdit} /></h1>
            </div>
            <PageHeader>
            "TEAMS, PROJECTS and SESSIONS MANAGEMENT"
            </PageHeader>
          </Col>
        </Row>
        <Row className="center-content">
          <Col md={10} lg={5}>
            <div className="card enterform">
              <div className="card-header">
                <div className="card-title">
                  <div className="brackets">
                    <span className="top-left"></span>
                    <span className="top-right"></span>
                    <span className="bottom-left"></span>
                    <span className="bottom-right"></span>
                  </div>
                  <span className="card-title-name">SIGN<b> UP</b></span>
                </div>
              </div>
              <div className="card-body">
                <Form>
                  
                    
                  
                  <FormGroup className="icon-input">
                    <i className="fa fa-user"></i>
                    <FormControl className="firstname" name="firstname" placeholder="Enter Firstname" type="text" />
                  </FormGroup>

                  <FormGroup className="icon-input">
                    <i className="fa fa-user"></i>
                    <FormControl className="lastname" name="lastname" placeholder="Enter Lastname" type="text" />
                  </FormGroup>

                  <FormGroup className="icon-input">
                    <i className="fa fa-envelope"></i>
                    <FormControl className="email" name="email" placeholder="Enter Email" type="text" />
                  </FormGroup>

                  <FormGroup className="icon-input">
                    <i className="fa fa-lock"></i>
                    <FormControl className="password" name="password" placeholder="Enter Password" type="password" />
                  </FormGroup>

                  <FormGroup className="icon-input">
                    <i className="fa fa-lock"></i>
                    <FormControl className="checkpassword" name="checkpassword" placeholder="Enter Password Again" type="password" />
                  </FormGroup>
                </Form>

                <div className="flex-row">
                  <Button bsStyle="primary" className="cancel" onClick={this.navigateLogin}>Cancel</Button>
                  <span className="separator"/>
                  <Button bsStyle="primary" onClick={this.onSubmit} value="Enviar">Register</Button>
                </div>
              </div>
              <div className="gradient-bottom" />
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }

}

export default withRouter(connect(null, { auth, signUp, getUser })(Register))

