import React, { Component } from 'react'
import { Button, Image } from 'react-bootstrap';

class NewMember extends Component {

  state = {
    firstName: "",
    lastName: "",
    email: ""
  }

  createMember = () => {

    const { firstName, lastName, email } = this.state

    // if empty values, alert to user and break
    if (firstName === "" || lastName === "" || email === ""){
      alert("Por favor, rellene los campos requeridos")
      return null
    }

    const member = {
      user: {
        email,
        firstName,
        lastName,
        validated: false
      },
      accepted: false,
      allowAccessOnWeekendsAndHolidays: false,
      calendar: null,
      devices: [],
      isAdmin: false,
      periods: []
    }
  
    this.props.addMemberToTeam(member)
  }

  render() {
    return (
      <tr>
        <td>
          <Image src={this.state.avatar} circle />
        </td>
        <td>
          <input
            type="text"
            name="firstName"
            className="form-control form-changing py-0"
            value={this.state.firstName}
            autoFocus
            placeholder="Nombre"
            onChange= {(evt) =>
              (this.setState({ firstName: evt.target.value }))
            }
          />
        </td>
        <td>
          <input
            type="text"
            name="lastName"
            className="form-control form-changing py-0"
            value={this.state.lastName}
            placeholder="Apellido"
            onChange= {(evt) =>
              (this.setState({ lastName: evt.target.value }))
            }
          />
        </td>
        <td>
          <input
            type="email"
            name="email"
            className="form-control form-changing py-0"
            value={this.state.email}
            placeholder="Email"
            onChange= {(evt) =>
              (this.setState({ email: evt.target.value }))
            }
          />
        </td>
        <td>
          {
            <React.Fragment>
              <Button
                name="close-button"
                className="icon-button mx-2"
                onClick={this.props.closeNewUser}
              >
                <i className="fa fa-close"></i>
              </Button>
              <Button
                name="accept-button"
                className="icon-button mx-2"
                onClick={this.createMember}
              >
                <i className="fa fa-check"></i>
              </Button>
            </React.Fragment>
          }
        </td>
      </tr>
    )
  }

}

export default NewMember
