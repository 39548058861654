import { Button, Checkbox, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap"
import { createWorkzone, getTeams } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class NewWorkzone extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      gmt: "",
      open: null,
      close: null
    }

    this.holiday = false
    this.weekends = false
  }

  handleOnClick = async () => {
    try {
      let openHour = parseInt(this.state.open.slice(0, 2))
      let openMinute = parseInt(this.state.open.slice(3, 6))
      let closeHour = parseInt(this.state.close.slice(0, 2))
      let closeMinute = parseInt(this.state.close.slice(3, 6))
      let workzone = {
        name: this.state.name,
        gmt: this.state.gmt,
        openTime: { hour: openHour, minute: openMinute },
        closeTime: { hour: closeHour, minute: closeMinute },
        openOnWeekends: this.weekends,
        openOnHolidays: this.holiday,
        beacons: ""
      }
      let resp = await this.props.createWorkzone(this.props.team._id, workzone)
      if (resp.error) alert("Ha habido un problema")
      else {
        this.props.getTeams()
        this.props.history.goBack()
      }
    } catch (err){
      console.log(err)
    }
  }

  checkNotEmpty = () => {
    return (this.state.name.length > 0 &&
      (this.state.gmt <= 12 && this.state.gmt >= -12) &&
      this.state.close &&
      this.state.open)
  }

  render(){
    return (
      <div className="dashboard-page">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav/>
          <div className="full-width">
            <Col xs={12} md={8} lg={6} offset={3}>
              <div className="main-content-area">
                <h2 className="section-title">WORKZONES. Nueva zona de trabajo </h2>
                <Form>
                  <FormGroup>
                    <ControlLabel>Nombre</ControlLabel>
                    <FormControl
                      type="text"
                      placeholder="Nombre"
                      onChange={evt => (this.setState({ name: evt.target.value }))}
                      defaultValue=""
                    >
                    </FormControl>
                    <ControlLabel>Zona horaria</ControlLabel>
                    <FormControl
                      id="gmt"
                      type="number"
                      onChange={evt => (this.setState({ gmt: evt.target.value }))}
                    >
                    </FormControl>
                    <ControlLabel>Hora de apertura</ControlLabel>
                    <FormControl
                      id="openhour"
                      onChange={evt => (this.setState({ open: evt.target.value }))}
                      type="time"
                      value={this.open}
                    >
                    </FormControl>
                    <ControlLabel>Hora de cierre</ControlLabel>
                    <FormControl
                      id="closehour"
                      onChange={evt => (this.setState({ close: evt.target.value }))}
                      type="time"
                      value={this.close}
                    >
                    </FormControl>
                    <ControlLabel>Abierto los fines de semana</ControlLabel>
                    <Checkbox
                      id="weekends"
                      defaultChecked={false}
                      onClick={() => (this.weekends = !this.weekends)}
                      label="Abierto los fines de semana"
                    >
                    </Checkbox>
                    <ControlLabel>Abierto en vacaciones</ControlLabel>
                    <Checkbox
                      id="holiday"
                      defaultChecked={false}
                      onClick={() => { this.holiday = !this.holiday }}
                    >
                    </Checkbox>
                  </FormGroup>
                </Form>
                <div className="flex-row">
                  <Button bsStyle="primary" className="cancel" onClick= {this.props.history.goBack}>Cancelar</Button>
                  <span className="separator"/>
                  <Button
                    disabled={!this.checkNotEmpty()}
                    bsStyle="primary"
                    type="button"
                    onClick={this.handleOnClick}
                  >Crea la zona de trabajo</Button>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    team: state.users.currentTeam,
    projects: state.projects
  }
}

const mapDispatchToProps = {
  createWorkzone,
  getTeams
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewWorkzone))
