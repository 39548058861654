import {
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup
} from "react-bootstrap"
import { getSessions, updateSession } from "../../actions/api"
import React from "react"
import { connect } from "react-redux"
import moment from "moment"
import { withRouter } from "react-router-dom"

class UpdateSession extends React.Component {

  constructor(props) {
    super(props)

    this.date = moment(this.props.currentSession.start).format("YYYY-MM-DD")
    this.project = this.props.currentSession.project
    this.start = moment(this.props.currentSession.start).format("HH:mm")
    this.end = moment(this.props.currentSession.end).format("HH:mm")
    this.tasks = this.props.currentSession && this.props.currentSession.tasks.length > 0 ? this.props.currentSession.tasks[0].desc : ""
    this.sick = this.props.currentSession.sick
    this.holiday = this.props.currentSession.holiday
  }

  render(){
    return (
      <Form>
        <h2>Update session</h2>
        <FormGroup>
          <ControlLabel>Fecha</ControlLabel>
          <FormControl
            id="date"
            onChange={evt => (this.date = evt.target.value)}
            type="date"
            defaultValue={this.date}
          />
          <br />
          <ControlLabel>Proyecto</ControlLabel>
          <FormControl
            componentClass="select"
            id="project"
            placeholder="Selecciona un proyecto"
            onChange={evt => (this.project = evt.target.value)}
          >
            <option key={"no-project"} value={null}>Selecciona un proyecto</option>
            {this.props.projects.map( project =>
              <option
                key={project._id}
                value={project._id}
                selected={this.props.currentSession.project._id === project._id}
              >
                {project.name}
              </option>
            )}
          </FormControl>
          <br />
          <ControlLabel>Periodo</ControlLabel>
          <FormControl
            id="periodstart"
            onChange={evt => (this.start = evt.target.value)}
            type="time"
            defaultValue={this.start}
          />
          <br />
          <FormControl
            id="periodend"
            onChange={evt => (this.end = evt.target.value)}
            type="time"
            defaultValue={this.end}
          />
          <br />
          <textarea
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Description task"
            onChange={evt => this.tasks = evt.target.value}
            defaultValue={this.tasks}
          >
          </textarea>
          <Checkbox id="sick" onChange={() => this.sick = !this.sick} defaultChecked={this.sick}>
            Enfermo
          </Checkbox>
          <Checkbox id="holiday" onChange={() => this.holiday = !this.holiday} defaultChecked={this.holiday}>
            Vacaciones
          </Checkbox>
        </FormGroup>
        <Button
          type="button"
          onClick={async () => {
            let session = {
              _id: this.props.currentSession._id,
              start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
              end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
              user: this.props.user._id,
              project: this.project,
              tasks: [{
                desc: this.tasks,
                start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
                end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
                absent: false,
                break: false,
                attachment: null
              }],
              attachment: null,
              holiday: this.holiday,
              sick: this.sick
            }
            let resp = await this.props.updateSession(session)
            if (resp.error) alert("Ha habido un problema")
            else {
              this.props.history.goBack()
            }
          }}
        >Update</Button>
        <Button onClick={() => this.props.history.goBack()}>Cancelar</Button>
      </Form>
    )
  }

}

const mapStateToProps = state => {
  return {
    currentSession: state.users.currentSession,
    user: state.users.activeUser,
    projects: state.projects
  }
}

const mapDispatchToProps = {
  updateSession,
  getSessions
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateSession)) //eslint-disable-line