import React from "react"
import { Table } from "react-bootstrap"
import { withRouter } from "react-router-dom"

class WorkzoneDetails extends React.Component {

  render() {
    let openMinute
    if (this.props.wkzn.openTime.minute !== null) {
      this.props.wkzn.openTime.minute.toString().length === 1 ?
        openMinute = "0" + this.props.wkzn.openTime.minute.toString() :
        openMinute = this.props.wkzn.openTime.minute.toString()
    }
    let closeMinute
    if (this.props.wkzn.openTime.hour !== null) {
      this.props.wkzn.closeTime.minute.toString().length === 1 ?
        closeMinute = "0" + this.props.wkzn.closeTime.minute.toString() :
        closeMinute = this.props.wkzn.closeTime.minute.toString()
    }

    return (
      <Table responsive>
        <tbody>
          <tr>
            <td><span>Zona horaria</span></td>
            <td><span>{this.props.wkzn.gmt} GMT</span></td>
          </tr>
          <tr>
            <td><span>Apertura / Cierre</span></td>
            <td><span>{this.props.wkzn.openTime.hour}:{openMinute} / {this.props.wkzn.closeTime.hour}:{closeMinute}</span></td>
          </tr>
          <tr>
            <td><span>Abre los fines de semana</span></td>
            <td><span>{this.props.wkzn.openOnWeekends ? "Sí" : "No"}</span></td>
          </tr>
          <tr>
            <td><span>Abre en vacaciones</span></td>
            <td><span>{this.props.wkzn.openOnHolidays ? "Sí" : "No"}</span></td>
          </tr>
        </tbody>
      </Table>
    )
  }

}

export default withRouter(WorkzoneDetails)
