import {
  apiCreateSession,
  apiDeleteSession,
  apiGetSessions,
  apiUpdateSession
} from "../actions/api"

export default (state = [], { type, payload, meta }) => {
  switch (type) {
  case apiCreateSession.types.success: {
    return [payload]
  }
  case apiGetSessions.types.success: {
    return payload
  }
  case apiUpdateSession.types.success: {
    return [payload]
  }
  case apiDeleteSession.types.success: {
    let sessions = state.filter(session => session._id !== meta.params.id)
    return [sessions]
  }
  default:
    return state
  }
}
