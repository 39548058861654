import { Button, Col, Row, Table } from "react-bootstrap"
import React, { Component } from "react"
import { getTeams, inviteTeamMember, updateTeam, updateUser } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Member from "./Member";
import NewMember from './NewMember';

class MembersList extends Component {

  state = {
    displayNewUser: false
  }

  displayNewUser = () => {
    this.setState({ displayNewUser: true })
  }

  closeNewUser = () => {
    this.setState({ displayNewUser: false })
  }

  addMemberToTeam = async (member) => {
    try {
      let resp =
        await this.props.inviteTeamMember(member, this.props.teamActual)
      if (resp.error) return alert("Lo sentimos, hubo un fallo.")
    } catch (error) {
      alert("Error: ", error)
    }

    this.setState({
      displayNewUser: false
    })

    // Reload the team members
    await this.props.getTeams()
  }

  updateUser = async (user) => {
    // Send new user to the api
    await this.props.updateUser(user)
    // Reload the team members
    await this.props.getTeams()
  }

  // Delete a member in the current team. It does not delete the user in the DB!
  deleteMember = async (user) => {
    let membersUpdated = this.props.teamActual.members.filter( member => (
      member.user._id !== user._id
    ))

    this.props.teamActual.members = membersUpdated
    await this.props.updateTeam( this.props.teamActual )
    // Reload the team members
    await this.props.getTeams()
  }

  render() {

    let newMemberInput = this.state.displayNewUser ?
      <NewMember
        key={new Date().getTime()}
        closeNewUser={this.closeNewUser}
        addMemberToTeam={this.addMemberToTeam}
      />
      : null

    return (
      <div className="dashboard-page">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">
            <h2 className="section-title mr-auto">MIEMBROS</h2>
            <Row className="justify-center">
              <Col md={10} lg={8}>
                <Row>
                  <Button
                    name="add-button"
                    className="add-btn ml-auto"
                    onClick={this.displayNewUser}
                  >
                    <span>Añadir miembro</span>
                    <i className="fa fa-plus-circle"></i>
                  </Button>
                </Row>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Avatar</th>
                      <th>Nombre</th>
                      <th>Apellido</th>
                      <th>Email</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.props.teamActual.members.map( (member) =>(
                      <Member
                        key={member.uniqueId}
                        user={member.user}
                        updateUser={this.updateUser}
                        deleteMember={this.deleteMember}
                      />
                    ))}

                    { newMemberInput }

                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    )
  }

}

const mapStateToProps = (state) => ({
  user: state.users.active_user,
  teamActual: state.teams.find(team => team._id === state.users.currentTeam._id)
})

const mapDispatchToProps = {
  inviteTeamMember,
  updateUser,
  updateTeam,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MembersList)) //eslint-disable-line