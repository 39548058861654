import {
  apiAcceptInvite,
  apiCreateTeam,
  apiDeleteTeam,
  apiGetTeamById,
  apiGetTeams,
  apiInviteTeamMember,
  apiUpdateTeam
} from "../actions/api"

export default (state = [], { type, payload, meta, ...action }) => {
  switch (type) {
  case apiGetTeams.types.success: {
    return payload
  }
  case apiGetTeamById.types.success: {
    return { ...state, ...payload }
  }
  case apiCreateTeam.types.success: {
    state.push(payload)
    return state
  }
  case apiUpdateTeam.types.success: {
    return state
  }
  case apiDeleteTeam.types.success: {
    const list = state.filter(team => team._id !== meta.params.tid)
    return list
  }
  case apiInviteTeamMember.types.success: {
    state.find(team => team._id === meta.params.id).members.push(meta.body)
    return state
  }
  case apiAcceptInvite.types.success: {
    return { ...state, ...payload }
  }
  default:
    return state
  }
}
