import { Button, Form, FormGroup, FormControl, ControlLabel, ListGroup, ListGroupItem } from "react-bootstrap"
import React, { Component } from "react"
import { deleteSession, getProjects, getSessions, setCurrentSession, setCurrentTeam } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import CustomModal from "../CustomModal"
import ModalNewSession from "../ModalNewSession"
import { connect } from "react-redux"
import SessionsListItem from "./SessionsListItem" //eslint-disable-line 
import moment from "moment"
import { withRouter } from "react-router-dom"

class SessionList extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="page dashboard-page" id="sessionsPage">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">
            <h2 className="section-title">SESSIONS</h2>
            <div className="sessions-table">
              <div className="top-table">
                <div className="cell flex-20">
                  <FormGroup>
                    <FormControl
                      placeholder="Find a session"
                    />
                    <Button href="/" className="icon-button"><i className="fa fa-search"/></Button>
                  </FormGroup>
                </div>
                <Button className="add-btn ml-auto" onClick={() => this.props.history.push("/sessions/new")}>
                  <span>Añadir nueva sesión</span><i className="fa fa-plus-circle"></i>
                </Button>
                <ModalNewSession />
              </div>
              <div className="header-table">
                <div className="cell flex-10">Sessions</div>
                <div className="flex-80">
                  <div className="flex-row">
                    <span className="flex-20 cell">Projects</span>
                    <span className="flex-50 cell">Description</span>
                    <span className="flex-30 cell">Start/End</span>
                  </div>
                </div>
                <div className="cell flex-10">Actions</div>
              </div>
              <ListGroup unstyled className="body-table">
                {(this.props.sessions[0] !== null && this.props.sessions[0] !== undefined && this.props.sessions.length !== 0) ? //eslint-disable-line
                  this.props.sessions.map((session, index) => ( //eslint-disable-line
                    <ListGroupItem key={session._id}>
                      <div className="cell flex-10 session-cell">
                        <span>{moment(session.start).format("DD/MM/YYYY")}</span>
                      </div>
                      <div className="flex-80">
                        <div className="project-item flex-row">
                          {session.project ?
                            <span className="cell column-projects flex-20">{session.project.name}</span>
                            : <span className="cell column-projects flex-20"></span>
                          }
                          {session.tasks ?
                            <span className="cell column-description flex-50">{session.tasks[0].desc}</span>
                            : <span className="cell column-description flex-50"></span>
                          }
                          <span className="cell column-date flex-30">
                            {moment(session.start).format("h:mm:ss")}
                            /
                            {moment(session.end).format("h:mm:ss")}
                          </span>
                        </div>
                      </div>
                      <CustomModal
                        updateAction={() => {
                          this.props.setCurrentSession(session)
                        }
                        }
                        deleteAction={() =>{
                          this.props.deleteSession(session)
                        }
                        }
                      >
                      </CustomModal>
                    </ListGroupItem>
                  ))
                  :
                  <div className="empty-state">
                    <span>No hay sesiones registradas</span>
                  </div>

                }
              </ListGroup>
            </div>
          </div>
        </div>
      </div>

    )
  }

}

const mapStateToProps = (state, props) => ({ //eslint-disable-line
  currentSession: state.users.currentSession,
  user: state.users.activeUser,
  sessions: state.sessions || [],
  auth: state.auth,
  projects: state.projects,
  teams: state.teams,
  currentTeam: state.teams.currentTeam,
  team: state.users.currentTeam
})

const mapDispatchToProps = {
  deleteSession,
  setCurrentTeam,
  getProjects,
  getSessions,
  setCurrentSession
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SessionList)) //eslint-disable-line
