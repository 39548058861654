import { Button, Checkbox, Col, ControlLabel, Form, FormControl, FormGroup, Grid } from "react-bootstrap"
import { getTeams, updateWorkzone } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class EditWorkzone extends React.Component {

  openTime = () =>{
    let openMinute
    this.props.workzone.openTime.minute.toString().length === 1 ?
      openMinute = "0" + this.props.workzone.openTime.minute.toString() :
      openMinute = this.props.workzone.openTime.minute.toString()
    let openHour
    this.props.workzone.openTime.hour.toString().length === 1 ?
      openHour = "0" + this.props.workzone.openTime.hour.toString() :
      openHour = this.props.workzone.openTime.hour.toString()
    return (openHour + ":" + openMinute)
  }

  closeTime = () => {
    let closeMinute
    this.props.workzone.closeTime.minute.toString().length === 1 ?
      closeMinute = "0" + this.props.workzone.closeTime.minute.toString() :
      closeMinute = this.props.workzone.closeTime.minute.toString()
    let closeHour
    this.props.workzone.closeTime.hour.toString().length === 1 ?
      closeHour = "0" + this.props.workzone.closeTime.hour.toString() :
      closeHour = this.props.workzone.closeTime.hour.toString()
    return (closeHour + ":" + closeMinute)
  }

  handleEdit = async () => {
    let wkzn = this.props.workzone
    let openHour = (this.open && parseInt(this.open.slice(0, 2))) || wkzn.openTime.hour
    let openMinute = (this.open && parseInt(this.open.slice(3, 6))) || wkzn.openTime.minute
    let closeHour = (this.close && parseInt(this.close.slice(0, 2))) || wkzn.closeTime.hour
    let closeMinute = (this.close && parseInt(this.close.slice(3, 6))) || wkzn.closeTime.minute
    let workzone = {
      _id: wkzn._id,
      name: this.name || wkzn.name,
      gmt: this.gmt || wkzn.gmt,
      openTime: {
        hour: openHour,
        minute: openMinute
      },
      closeTime: {
        hour: closeHour,
        minute: closeMinute
      },
      openOnWeekends: this.weekends,
      openOnHolidays: this.holiday,
      beacons: ""
    }
    let resp = await this.props.updateWorkzone(
      this.props.team._id,
      workzone
    )
    if (resp.error) alert("Ha habido un problema")
    else {
      this.props.getTeams()
      this.props.history.goBack()
    }
  }
  render() {
    let wkzn = this.props.workzone
    this.weekends = wkzn.openOnWeekends
    this.holiday = wkzn.openOnHolidays
    return (
      <div className="dashboard-page">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav/>
          <Grid className="full-width">
            <Col xs={12} md={8} lg={6} offset={3}>
              <div className="main-content-area">
                <h2 className="section-title">Editar zona de trabajo </h2>
                <Form>
                  <FormGroup>
                    <ControlLabel>Nombre</ControlLabel>
                    <FormControl
                      id="nombre"
                      onChange={evt => (this.name = evt.target.value)}
                      type="text"
                      defaultValue={wkzn.name}
                    />
                    <ControlLabel>Zona Horaria</ControlLabel>
                    <FormControl
                      id="gmt"
                      onChange={evt => (this.gmt = evt.target.value)}
                      type="number"
                      defaultValue={wkzn.gmt}
                    />
                    <ControlLabel>Abre:</ControlLabel>
                    <FormControl
                      id="openhour"
                      onChange={evt => (this.open = evt.target.value)}
                      placeholder="Hora"
                      type="time"
                      defaultValue={this.openTime()}
                    />
                    <ControlLabel>Cierra:</ControlLabel>
                    <FormControl
                      id="closehour"
                      onChange={evt => (this.close = evt.target.value)}
                      placeholder="Hora"
                      type="time"
                      defaultValue={this.closeTime()}
                    />
                    <Checkbox
                      id="weekends"
                      defaultChecked={wkzn.openOnWeekends}
                      defaultValue={wkzn.openOnWeekends}
                      onClick={() => this.weekends = !this.weekends}
                    >Abierto los fines de semana</Checkbox>
                    <Checkbox
                      id="holiday"
                      defaultChecked={wkzn.openOnHolidays}
                      defaultValue={wkzn.openOnHolidays}
                      onClick={() => this.holiday = !this.holiday}
                    >Abierto en vacaciones</Checkbox>
                  </FormGroup>
                  <div className="flex-row">
                    <Button bsStyle="primary" className="cancel" onClick={this.props.history.goBack}>Cancelar</Button>
                    <span className="separator"/>
                    <Button bsStyle="primary" onClick={this.handleEdit}>Guardar</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Grid>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    team: state.users.currentTeam,
    projects: state.projects,
    teams: state.teams.list,
    workzone: state.users.currentWorkzone
  }
}

const mapDispatchToProps = {
  updateWorkzone,
  getTeams
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditWorkzone))
