import * as types from "../actions/actionTypes"
import {
  apiChangePass,
  apiGetUser,
  apiResetPass,
  apiSignUp,
  apiTestToken
} from "../actions/api"

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiGetUser.types.success: {
    return { ...state, activeUser: payload }
  }
  case apiSignUp.types.success: {
    return { ...state, ...payload }
  }
  case apiResetPass.types.success: {
    return { ...state, ...payload }
  }
  case apiChangePass.types.success: {
    return { ...state, ...payload }
  }
  case apiTestToken.types.success: {
    return { ...state, ...payload }
  }
  case types.DESTROY_TOKEN: {
    return []
  }
  case types.SET_CURRENT_TEAM: {
    return { ...state, currentTeam: payload.newCurrentTeam }
  }
  case types.SET_CURRENT_SESSION: {
    return { ...state, currentSession: payload.newCurrentSession }
  }
  case types.SET_CURRENT_CALENDAR: {
    return { ...state, currentCalendar: payload.newCurrentCalendar }
  }
  case types.SET_CURRENT_WORKZONE: {
    return { ...state, currentWorkzone: payload.newCurrentWorkzone }
  }
  default:
    return state
  }
}
