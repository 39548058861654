import { Alert, Button, Col, Row } from "react-bootstrap"
import React, { Component } from "react"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import ModalEditWorkzone from "./ModalEditWorkzone"
import ModalNewWorkzone from "./ModalNewWorkzone"
import WorkzoneDetails from "./WorkzoneDetails"
import { connect } from "react-redux"
import { setCurrentWorkzone } from "../../actions/api"
import { withRouter } from "react-router-dom"

class WorkzonesList extends Component {

  render() {
    return (
      <div className="dashboard-page">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">
            <h2 className="section-title">
              Zonas de trabajo
            </h2>
            <Row className="margin-horizontal-90">
              {(this.props.workzones !== null && this.props.workzones !== undefined && this.props.workzones.length !== 0) ? //eslint-disable-line
                this.props.workzones.map(wkzn => {
                  return (
                    <Col xs={12} md={6} key={wkzn._id}>
                      <div className="card simple-table-card">
                        <div className="card-header">
                          <span className="mr-auto">{wkzn.name}</span>
                          <ModalEditWorkzone
                            currentWorkzone={wkzn}
                          >
                          </ModalEditWorkzone>
                          <Button
                            className="icon-button"
                            onClick={() => {
                              /*this.props.deleteWorkzone(wkzn.team, wkzn._id)
                              this.props.getWorkzones(this.props.tid)*/
                            }}
                          ><i className="fa fa-trash"></i>
                          </Button>
                        </div>
                        <WorkzoneDetails wkzn={wkzn} />
                      </div>
                    </Col>
                  )
                })
                :
                <Alert bsStyle="warning" className="full-width">
                  <i className="fa fa-exclamation-triangle" />
                  <div className="alert-content">
                    <h4>No hay zonas de trabajo para este equipo</h4>
                    <hr />
                  </div>
                </Alert>
              }
              <Col xs={12} md={6}>
                <ModalNewWorkzone />
              </Col>
            </Row>
          </div>
        </div>
      </div >

    )
  }

}

const mapStateToProps = (state, props) => ({ //eslint-disable-line
  auth: state.users,
  user: state.users.active_user,
  team: state.users.currentTeam,
  workzones:
    state.teams.find(team => team._id === state.users.currentTeam._id).workzones
})

const mapDispatchToProps = {
  setCurrentWorkzone
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkzonesList)) //eslint-disable-line
