import * as types from "./actionTypes"
import { baseUrl } from "../config"
import { createApiAction } from "redux-api-action"

//============ USERS & AUTH ============================================================================================================

export const apiTestToken = createApiAction(baseUrl,"POST", "/api/auth")
export const testToken = (token) => apiTestToken({ body: { token } })

export const apiAuth = createApiAction(baseUrl,"POST", "/api/auth")
export const auth = (email, password) => apiAuth({ body: { email, password } })

export const apiSignUp = createApiAction(baseUrl, "POST", "/api/users")
export const signUp = (firstName, lastName, email, password) => apiSignUp({ body: { firstName, lastName, email, password } })

export const apiGetUser = createApiAction(baseUrl, "GET", "/api/users/:id")
export const getUser = (id) => apiGetUser({ params: { id } })

export const apiUpdateUser = createApiAction(baseUrl, "PUT", "/api/users/:id")
export const updateUser = (user) => apiUpdateUser({ params: { id: user._id } , body: user })

export const apiResetPass = createApiAction(baseUrl, "POST", "/api/users/:email/resetpassword")
export const resetPass = (email, lang) => apiResetPass({ params: { email }, body: { lang } })

export const apiChangePass = createApiAction(baseUrl, "POST", "/api/users/:email/changepassword")
export const changePass = (email, password) => apiChangePass({ params: { email }, body: { password } })

//============ TEAMS ============================================================================================================

export const apiGetTeams = createApiAction(baseUrl, "GET", "/api/teams")
export const getTeams = () => apiGetTeams()

export const apiGetTeamById = createApiAction(baseUrl, "GET", "/api/teams/:id")
export const getTeamById = (id) => apiGetTeamById({ params: { id } })

export const apiCreateTeam = createApiAction(baseUrl, "POST", "/api/teams")
export const createTeam = (team) => apiCreateTeam ({ body: team })

export const apiUpdateTeam = createApiAction(baseUrl, "PUT", "/api/teams/:id")
export const updateTeam = (team) => apiUpdateTeam({ params: { id: team._id }, body: team })

export const apiDeleteTeam = createApiAction(baseUrl, "DELETE", "/api/teams/:tid")
export const deleteTeam = (tid) => apiDeleteTeam({ params: { tid } })

export const apiInviteTeamMember = createApiAction(baseUrl, "POST", "/api/teams/:id/invite")
export const inviteTeamMember = (member, team) => apiInviteTeamMember({ params: { id: team._id }, body: member })

export const apiAcceptInvite = createApiAction(baseUrl, "POST", "/api/teams/:tid/accept_invite")
export const acceptInvite = (tid) => apiAcceptInvite({ params: { tid } })

//============ SESSIONS ============================================================================================================

export const apiCreateSession = createApiAction(baseUrl, "POST", "/api/sessions")
export const createSession = (session) => apiCreateSession({ body: session })

export const apiUpdateSession = createApiAction(baseUrl, "PUT", "/api/sessions/:id")
export const updateSession = (session) => apiUpdateSession({ params: { id: session._id }, body: session })

export const apiDeleteSession = createApiAction(baseUrl, "DELETE", "/api/sessions/:id")
export const deleteSession = (session) => apiDeleteSession({ params: { id: session._id } })

export const apiGetSessions = createApiAction(baseUrl, "GET", "/api/sessions")
export const getSessions = (from, to, teamId) => apiGetSessions({ query: { from, to, team: teamId } })

//============ PROJECTS  ============================================================================================================

export const apiCreateProject = createApiAction(baseUrl, "POST", "/api/teams/:tid/projects")
export const createProject = (tid, project) => apiCreateProject({ params: { tid }, body: project })

export const apiGetProjects = createApiAction(baseUrl, "GET", "/api/teams/:tid/projects")
export const getProjects = (tid) => apiGetProjects({ params: { tid } })

export const apiUpdateProject = createApiAction(baseUrl, "PUT", "/api/teams/:tid/projects/:pid")
export const updateProject = (tid, project) => apiUpdateProject({ params: { tid, pid: project._id } , body: project })

export const apiDeleteProject = createApiAction(baseUrl, "DELETE", "/api/teams/:tid/projects/:pid")
export const deleteProject = (tid, pid) => apiDeleteProject({ params: { tid, pid } })

//============ ATTACHMENTS, CALENDARS, WORKZONES, REPORTS ==============================================================================

export const apiUploadAttachment = createApiAction(baseUrl, "POST", "/api/attachments")
export const uploadAttachment = (dataFile) => apiUploadAttachment({ body: { dataFile } })

export const apiDeleteAttachment = createApiAction(baseUrl, "DELETE", "/api/attachments/:fileName")
export const deleteAttachment = (fileName) => apiDeleteAttachment({ params: { fileName } })

export const apiGetDetections = createApiAction(baseUrl, "GET", "/api/detections")
export const getDetections = (from, to) => apiGetDetections({ query: { from, to } })

export const apiCreateCalendar = createApiAction(baseUrl, "POST", "/api/teams/:tid/calendars")
export const createCalendar = (tid, calendar) => apiCreateCalendar({ params: { tid }, body: calendar })

export const apiGetCalendars = createApiAction(baseUrl, "GET", "/api/calendars/:tid")
export const getCalendars = (tid) => apiGetCalendars({ params: { tid } })

export const apiUpdateCalendar = createApiAction(baseUrl, "PUT", "/api/teams/:tid/calendars/:cid")
export const updateCalendar = (tid, calendar) => apiUpdateCalendar({ params: { tid, cid: calendar._id }, body: calendar })

export const apiCreateWorkzone = createApiAction(baseUrl, "POST", "/api/teams/:tid/workzones")
export const createWorkzone = (tid, workzone) => apiCreateWorkzone({ params: { tid }, body: workzone })
/**************************************************************************************/
export const apiGetWorkzones = createApiAction(baseUrl, "GET", "/api/teams/:tid/workzones")
export const getWorkzones = (tid) => apiGetWorkzones({ params: { tid } })

export const apiUpdateWorkzone = createApiAction(baseUrl, "PUT", "/api/teams/:tid/workzones/:wid")
export const updateWorkzone = (tid, workzone) => apiUpdateWorkzone({ params: { tid, wid: workzone._id }, body: workzone })

export const apiDeleteWorkzone = createApiAction(baseUrl, "DELETE", "/api/teams/:tid/workzones/:pid")
export const deleteWorkzone = (tid, pid) => apiDeleteWorkzone({ params: { tid, pid } })
/**************************************************************************************/
export const apiGetNotifications = createApiAction(baseUrl, "GET", "/api/teams/:tid/notifications")
export const getNotifications = (tid, from, to) => apiGetNotifications({ query: { from, to }, params: { tid } })

export const apiGetReports = createApiAction(baseUrl, "GET", "/api/teams/:tid/reports")
export const getReports = (tid, from, to) => apiGetReports({ query: { from, to }, params: { tid } })

export const apiUserReport = createApiAction(baseUrl, "GET", "/api/teams/:tid/reports")
export const userReport = (tid, daily, userId, from, to) => apiUserReport({ query: { daily, userId, from, to }, params: { tid } })

export const apiProjectReport = createApiAction(baseUrl, "GET", "/api/teams/:tid/reports")
export const projectReport = (tid, daily, projectName, from, to) => apiProjectReport({ query: { daily, projectName, from, to }, params: { tid } })

//Funciones internas:

export const setCurrentTeam = (team) => ({
  type: types.SET_CURRENT_TEAM,
  payload: { newCurrentTeam: team }
})

export const setCurrentSession = (session) => ({
  type: types.SET_CURRENT_SESSION,
  payload: { newCurrentSession: session }
})

export const setCurrentCalendar = (calendar) => ({
  type: types.SET_CURRENT_CALENDAR,
  payload: { newCurrentCalendar: calendar }
})

export const setCurrentWorkzone = (workzone) => ({
  type: types.SET_CURRENT_WORKZONE,
  payload: { newCurrentWorkzone: workzone }
})
///////////////////////////
