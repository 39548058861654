import React from "react"
import { connect } from "react-redux"
import moment from "moment"

const SessionsListItem = ({ from, to, project, holiday, sick, delAction, tasks, updateAction }) => { //eslint-disable-line
  return (
    <div>
      <div>{"From: " + moment(from).format("DD-MM-YYYY HH:mm")}</div>
      <div>{"To: " + moment(to).format("DD-MM-YYYY HH:mm")}</div>
      <div>{"Description task: " + tasks}</div>
      <div>{"Project: " + project}</div> 
      { holiday && <div>{"Vacaciones"}</div> }
      { sick && <div>{"Enfermo"}</div> }
      <button onClick={delAction}>Eliminar</button>
      <button onClick={updateAction}>Update session</button>
    </div>
  )
}

export default connect(null,null)(SessionsListItem)
