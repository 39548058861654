import {
  apiCreateCalendar,
  apiGetCalendars,
  apiUpdateCalendar
} from "../actions/api"

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiCreateCalendar.types.success: {
    return payload
  }
  case apiGetCalendars.types.success: {
    return payload
  }
  case apiUpdateCalendar.types.success: {
    return { ...state, ...payload }
  }
  default:
    return state
  }
}
