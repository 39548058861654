import React, { Component } from "react"
import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav"
import { Link } from "react-router-dom"
import LogOut from "./Auth/LogOut"
import { Button } from "react-bootstrap"
import { connect } from "react-redux"
import { logOut } from "../actions/logOutAction"
import { withRouter } from "react-router-dom"

class ComponentSaveNav extends Component {

  render() {
    return (
      <SideNav
        className = "sidebar-container"
        onSelect={(selected) => { //eslint-disable-line
        // Add your code here
        }}
      >
        <SideNav.Toggle className = "sidebar-toogle" />
        <SideNav.Nav className = "sidebar-menu">
          <NavItem className = "sidebar-item" eventKey="sessions">
            <NavIcon><Link to="/sessions"><i className = "fe fe-clock" /></Link></NavIcon>
            <NavText><Link to="/sessions">Sessions</Link></NavText>
          </NavItem>
          <NavItem className = "sidebar-item" eventKey="projects">
            <NavIcon><Link to="/projects"><i className = "fa fa-briefcase" /></Link></NavIcon>
            <NavText><Link to="/projects">Projects</Link></NavText>
          </NavItem>
          <NavItem className = "sidebar-item" eventKey="members">
            <NavIcon><Link to="/members"><i className = "fa fa-user" /></Link></NavIcon>
            <NavText><Link to="/members">Members</Link></NavText>
          </NavItem>
          <NavItem className = "sidebar-item" eventKey="calendar">
            <NavIcon><Link to="/calendars"><i className = "fa fa-calendar"></i></Link></NavIcon>
            <NavText><Link to="/calendars">Calendars</Link></NavText>
          </NavItem>
          <NavItem className = "sidebar-item" eventKey = "notifications">
            <NavIcon><Link to="/notifications"><i className = "fa fa-bell" /></Link></NavIcon>
            <NavText><Link to="/notifications">Notificaciones</Link></NavText>
          </NavItem>
          <NavItem className = "sidebar-item" eventKey = "reports">
            <NavIcon><Link to="/reports"><i className="fa fa-file" /></Link></NavIcon>
            <NavText><Link to="/reports">Reports</Link></NavText>
          </NavItem>
          <NavItem className="sidebar-item" eventKey="workzones">
            <NavIcon><Link to="/workzones"><i className = "fa fa-exclamation-circle" /></Link></NavIcon>
            <NavText><Link to="/workzones">Workzones</Link></NavText>
          </NavItem>

          <NavItem eventKey="close" className="sidebar-bottom sidebar-item">
            <NavIcon>
              <Button onClick={() => {
                this.props.logOut()
                this.props.history.push("/")
              }}
              >
                <i className = "fa fa-power-off" />
              </Button>
            </NavIcon>
            <NavText>
              <Button onClick={() => {
                this.props.logOut()
                this.props.history.push("/")
              }}
              >
                Log Out
              </Button>
            </NavText>
            
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    )
  }


}

const mapDispatchToProps = { logOut }

export default withRouter(connect(null, mapDispatchToProps)(ComponentSaveNav))