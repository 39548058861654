//teams:
export const SET_CURRENT_TEAM = "SET_CURRENT_TEAM"

//global:
export const DESTROY_TOKEN = "DESTROY_TOKEN"

//sessions:
export const SET_CURRENT_SESSION = "SET_CURRENT_SESSION"

//calendars:
export const SET_CURRENT_CALENDAR = "SET_CURRENT_CALENDAR"

//workzones:
export const SET_CURRENT_WORKZONE = "SET_CURRENT_WORKZONE"
