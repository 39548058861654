import { apiGetReports, apiUserReport, apiProjectReport } from "../actions/api";
export default (state = [], { type, payload, ...action }) => {
  switch (type) {
    case apiGetReports.types.success: {
      return { ...state, ...payload };
    }
    case apiUserReport.types.success: {
      return { ...state, ...payload };
    }
    case apiProjectReport.types.success: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
