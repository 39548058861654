import { Alert, Button, Form, Grid, Header, Nav, Page } from "tabler-react"
import { Link, withRouter } from "react-router-dom"
import { createSession, getSessions } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import React from "react"
import { connect } from "react-redux"
import moment from "moment"

class NewSession extends React.Component {

  constructor(props) {
    super(props)

    this.sick = false
    this.holiday = false
  }

  render() {
    return (
      <Page className="dashboard-page">
        <ComponentHeader></ComponentHeader>
        <Page.Main>
          <ComponentSaveNav />
          <div className="main-content-area">
            <Header.H2 className="section-title">SESSIONS. Crear nueva sesión </Header.H2>
            <Grid.Col xs={12} md={8} lg={6} offset={3}>
              {
                !this.props.projects[0] ?
                  (
                    <Alert type="danger" icon="alert-triangle">
                      <div className="alert-content">
                        <span>No existen proyectos</span>
                        <hr />
                        <Link to="/projects">Por favor, crea tu nuevo proyecto<i className="fa fa-plus-circle" /></Link>
                      </div>
                    </Alert>
                  )
                  :
                  <Form.FieldSet className="custom-form" name="Crear nueva sesion">
                    <Form.Group label="Fecha" isRequired>
                      <Form.Input
                        id="date"
                        onChange={evt => (this.date = evt.target.value)}
                        type="date"
                        value={this.date}
                      >
                      </Form.Input>
                    </Form.Group>

                    <Form.Group isRequired className="margin-left-110">
                      <Form.Checkbox id="sick" onChange={() => this.sick = !this.sick} defaultChecked={this.sick} label="Enfermo" />

                      <Form.Checkbox id="holiday" onChange={() => this.holiday = !this.holiday} defaultChecked={this.holiday} label="Vacaciones" />
                    </Form.Group>

                    <Form.Group label="Proyecto" isRequired>
                      <Nav>
                        {this.props.projects.map((project) => {
                          return (
                            <Nav.Item
                              value={project.name}
                              onClick={() => (this.project = project)}

                            />
                          )
                        })
                        }
                      </Nav>
                    </Form.Group>

                    <Form.Group label="Periodo" isRequired>
                      <Form.Input
                        id="periodstart"
                        onChange={evt => (this.start = evt.target.value)} //eslint-disable-line
                        type="time"
                        value={this.start}
                      >
                      </Form.Input>
                      <Form.Input
                        id="periodend"
                        onChange={evt => (this.end = evt.target.value)}
                        type="time"
                        value={this.end}
                      >
                      </Form.Input>
                    </Form.Group>
                    <Form.Group label="Descripción" isRequired>
                      <Form.Textarea
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Description task"
                        onChange={evt => this.tasks = evt.target.value}
                      >
                      </Form.Textarea>
                    </Form.Group>

                    <div className="botom-actions">
                      <Button
                        type="button"
                        onClick={async () => {
                          let session = {
                            start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
                            end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
                            user: this.props.user._id,
                            project: this.project,
                            tasks: [{
                              desc: this.tasks,
                              start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
                              end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
                              absent: false,
                              break: false,
                              attachment: null
                            }],
                            attachment: null,
                            holiday: this.holiday,
                            sick: this.sick
                          }
                          let resp = await this.props.createSession(session)
                          if (resp.error) alert("Ha habido un problema")
                          else {
                            this.props.history.goBack()
                          }
                        }}
                      >Crea la sesión
                      </Button>
                      <Button onClick={() => this.props.history.goBack()}>
                        Cancelar
                      </Button>
                    </div>
                  </Form.FieldSet>
              }
            </Grid.Col>
          </div>
        </Page.Main>
      </Page>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    projects: state.projects
  }
}

const mapDispatchToProps = {
  createSession,
  getSessions
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewSession)) //eslint-disable-line
