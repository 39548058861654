import * as types from "../actions/actionTypes"
import { applyMiddleware, combineReducers, createStore } from "redux"
import { apiMiddleware } from "redux-api-middleware"
import { composeWithDevTools } from "redux-devtools-extension"
import reducers from "../reducers"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}


const reducer = combineReducers({ ...reducers })

const middlewares = composeWithDevTools({})(
  applyMiddleware(thunk),
  applyMiddleware(apiMiddleware)
)

/**
 * @param {object} initialState
 * @param {boolean} options.isServer indicates whether it is a server side or client side
 * @param {Request} options.req NodeJS Request object (not set when client applies initialState from server)
 * @param {Request} options.res NodeJS Request object (not set when client applies initialState from server)
 * @param {boolean} options.debug User-defined debug mode param
 * @param {string} options.storeKey This key will be used to preserve store in global namespace for safe HMR
 */

const rootReducer = (state, action) => {
  if (action.type === types.DESTROY_TOKEN) {
    state = undefined
  }
  return reducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const configureStore = initialState => {
  let store = createStore(persistedReducer, initialState, middlewares)
  let persistor = persistStore(store)
  return { store, persistor }
}
