import { Button, Col, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap"
import ComponentSaveNav from "../ComponentSaveNav"
import React from "react"
import { connect } from "react-redux"
import { inviteTeamMember } from "../../actions/api"
import { withRouter } from "react-router-dom"

class AddMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      email: "",
      name: "",
      lastname: ""
    }
  }

  onSubmit = async (member, team) => {
    try {
      let resp = await this.props.inviteTeamMember(member, team)
      if (resp.error) return alert("Lo sentimos, hubo un fallo.")
      this.props.history.goBack()
    } catch (error) {
      alert("Error: ", error)
    }
  }
  handleOnClick = () => {
    let member = {
      user: {
        email: this.state.email,
        firstName: this.state.name,
        lastName: this.state.lastname,
        validated: false
      },
      accepted: false,
      allowAccessOnWeekendsAndHolidays: false,
      calendar: null,
      devices: [],
      isAdmin: false,
      periods: []
    }
    member.user.email === "" || member.user.firstName === "" || member.user.lastName === "" ?
      alert("Por favor, rellene los campos requeridos") :
      this.onSubmit(member, this.props.team)
  }

  render() {
    return (
      <div className="dashboard-page">
        <div className="page-header"></div>
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">
            <h2 className="section-title">MEMBERS. Invitar miembro</h2>
            <Col xs={12} md={8} lg={6} offset={3}>
              <Form>
                <FormGroup>
                  <ControlLabel>Nombre</ControlLabel>
                  <FormControl
                    className="firstname"
                    name="firstname"
                    type="text"
                    placeholder="Nombre"
                    onChange={evt => (
                      this.setState({ name: evt.target.value }))
                    }
                    defaultValue=""
                  ></FormControl >
                </FormGroup >
                <FormGroup>
                  <ControlLabel>Apellidos</ControlLabel>
                  <FormControl
                    className="lastname"
                    name="lastname"
                    type="text"
                    placeholder="Apellidos"
                    onChange={evt => (
                      this.setState({ lastname: evt.target.value }))
                    }
                    defaultValue=""
                  ></FormControl>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>E-mail</ControlLabel>
                  <FormControl
                    className="email"
                    name="email"
                    type="email"
                    placeholder="ejemplo@ejemplo"
                    onChange={evt => (
                      this.setState({ email: evt.target.value }))
                    }
                    defaultValue=""
                  ></FormControl>
                </FormGroup>
                <div className="flex-row">
                  <Button bsStyle="primary" className="cancel" onClick={() => this.props.history.goBack()}>Cancelar</Button>
                  <span className="separator"/>
                  <Button bsStyle="primary" onClick={this.handleOnClick}>Continuar</Button>
                </div>
              </Form>
            </Col>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  auth: state.users,
  user: state.users.active_user || "",
  team: state.users.currentTeam
})

const mapDispatchToProps = {
  inviteTeamMember
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddMembers)) //eslint-disable-line