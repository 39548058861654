import { Button, Col , Form, FormGroup, FormControl, ControlLabel} from "react-bootstrap"
import { getTeams, updateCalendar } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class UpdateCalendar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      name: this.props.currentCalendar.name,
      holidays: this.props.currentCalendar.holidays
    }
  }

  render() {
    return (
      <div className="page dashboard-page">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav/>
          <div className="full-width">
            <Col md={8} lg={6} offset={3}>
              <div className="main-content-area">
                <h2 className="section-title">CALENDARS</h2>
                <h3 className="section-title">Actualizar calendario</h3>

                <Form name="Edit calendar">

                  <FormGroup>
                    <ControlLabel>Nombre</ControlLabel>
                    <FormControl
                      label= "Nombre"
                      id="calendarName"
                      onChange={evt => (this.setState({ name: evt.target.value }))} //eslint-disable-line
                      type="text"
                      placeholder={this.state.name}
                    />        
                  </FormGroup>

                    Dia
                    Mes
                    <Button onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays, { day: "", month: "" }]
                      }))
                    }}
                    >+</Button>
                    <Button onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays.slice(0,prevState.holidays.length - 1)] //eslint-disable-line
                      }))
                    }}
                    >−</Button>
                    {
                      this.state.holidays.map((holiday, index) => {
                        let dayId = "day" + index, monthId = "month" + index
                        return (
                          <FormGroup key={dayId + monthId}>
                            <FormControl
                              placeholder={holiday.day}
                              id={dayId}
                              onChange={evt => (holiday.day = evt.target.value)}
                              type="text"
                            />
                            <FormControl
                              placeholder = {holiday.month}
                              id={monthId}
                              onChange={evt => (holiday.month = evt.target.value)} //eslint-disable-line
                              type="text"
                            />
                          </FormGroup>
                        )
                      })
                    }
                  <div className="flex-row">
                    <Button
                      bsStyle="primary"
                      type="button"
                      onClick={async () => {
                        let calendar = {
                          _id: this.props.currentCalendar._id,
                          name: this.state.name,
                          holidays: this.state.holidays
                        }
                        let resp = await this.props.updateCalendar(this.props.team._id, calendar) //eslint-disable-line
                        if (resp.error) alert("Ha habido un problema")
                        else {
                          this.props.getTeams()
                          this.props.history.goBack()
                        }
                      }}
                    >
                      Actualizar
                    </Button>
                    <span className="separator"/>
                    <Button bsStyle="primary" className="cancel" onClick={() => this.props.history.goBack()}>
                      Cancel
                    </Button>
                  </div>

                </Form>
              </div>
            </Col>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    team: state.users.currentTeam,
    currentCalendar: state.users.currentCalendar
  }
}

const mapDispatchToProps = {
  updateCalendar,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateCalendar)) //eslint-disable-line