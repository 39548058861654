import {
  apiCreateProject,
  apiDeleteProject,
  apiGetProjects,
  apiUpdateProject
} from "../actions/api"

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiCreateProject.types.success: {
    let newState = state.slice()
    newState.push(payload)
    return newState
  }
  case apiGetProjects.types.success: {
    return payload
  }
  case apiUpdateProject.types.success: {
    return state
  }
  case apiDeleteProject.types.success: {
    let projectId = action.meta.params.pid
    let stateCopy = state.slice()
    let newState = stateCopy.filter(project => project._id !== projectId)
    return newState
  }
  default:
    return state
  }
}
