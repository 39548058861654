import { Button, ControlLabel, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap"
import { getTeams, updateCalendar } from "../../actions/api"
import Modal from "react-modal"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

class ModalUpdateCalendar extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      name: this.props.currentCalendar.name,
      holidays: this.props.currentCalendar.holidays
    }
  }

  componentWillReceiveProps(nextProps) { //eslint-disable-line
    const { currentCalendar } = nextProps
    if (currentCalendar && currentCalendar.name !== this.state.name) {
      this.setState({ name: currentCalendar.name })
    }
    if (currentCalendar && currentCalendar.holidays !== this.state.holidays) {
      this.setState({ holidays: currentCalendar.holidays })
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyles}
      >
        <Grid>
          <div className="card">
            <div className="card-header">
              <span>Editar calendario</span>
              <Button className="btn-close" onClick={this.props.close}><i className="fa fa-times" /></Button>
            </div>
            <div className="card-body">
              <Form className="custom-form">
                <FormGroup>
                  <ControlLabel>Nombre</ControlLabel>
                  <FormControl
                    id="calendarName"
                    onChange={evt => (this.setState({ name: evt.target.value }))} //eslint-disable-line
                    type="text"
                    //CAMBIO
                    defaultValue={this.state.name}
                  />
                </FormGroup>

                <div className="holidays-container">
                  <span className="fake-label">Vacaciones</span>
                  <div className="margin-left-110 add-holidays-container">
                    {
                      //CAMBIO
                      this.state.holidays.map((holiday, index) => {
                        let dayId = "day" + index, monthId = "month" + index
                        return (
                          <div className="flex-row" key={dayId + monthId}>
                            <FormGroup >
                              <ControlLabel>Día</ControlLabel>
                              <FormControl
                                defaultValue={holiday.day}
                                id={dayId}
                                onChange={evt => (holiday.day = evt.target.value)} //eslint-disable-line
                                type="text"
                              />
                              <ControlLabel>Mes</ControlLabel>
                              <FormControl
                                defaultValue={holiday.month}
                                id={monthId}
                                onChange={evt => (holiday.month = evt.target.value)} //eslint-disable-line
                                type="text"
                              />
                            </FormGroup>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="flex-row margin-botton-20">
                  <span className="ml-auto fake-btn">Añadir/Eliminar festivo</span>
                  <Button
                    className="icon-button add-btn"
                    onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays, { day: "", month: "" }]
                      }))
                    }}
                  ><i className="fa fa-plus-circle"></i>
                  </Button>
                  <Button
                    className="icon-button add-btn"
                    onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays.slice(0, prevState.holidays.length - 1)] //eslint-disable-line
                      }))
                    }}
                  ><i className="fa fa-minus-circle"></i>
                  </Button>
                </div>
                <div className="flex-row margin-left-110 ">
                  <Button bsStyle="primary" className="cancel" onClick={this.props.close}>Cancelar</Button>
                  <span className="separator" />
                  <Button
                    bsStyle="primary"
                    type="button"
                    onClick={async () => {
                      let calendar = {
                        _id: this.props.currentCalendar._id,
                        name: this.state.name,
                        holidays: this.state.holidays
                      }
                      let resp = await this.props.updateCalendar(this.props.team._id, calendar) //eslint-disable-line
                      if (resp.error) alert("Ha habido un problema")
                      else {
                        this.props.getTeams()
                        this.props.close()
                      }
                    }}
                  >Guardar</Button>
                </div>
              </Form>
            </div>
          </div>
        </Grid>
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return {
    team: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  updateCalendar,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalUpdateCalendar)) //eslint-disable-line