
import { Button, ButtonGroup, Checkbox, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap"
import Modal from "react-modal"
import React from "react"
import "react-times/css/classic/default.css" //eslint-disable-line
import "react-times/css/material/default.css"
import { getSessions, updateSession } from "../actions/api"
import { connect } from "react-redux"
import moment from "moment"
import { withRouter } from "react-router-dom"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

Modal.setAppElement(document.getElementById("root"))

class CustomModal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      desc: "",
      modalIsOpen: false,
      date: "",
      project: "",
      start: "",
      end: "",
      tasks: [],
      sick: "",
      holliday: ""
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  static getDerivedStateFromProps(props, state){
    if (props.currentSession !== state.currentSession){
      state.date = moment(props.currentSession.start).format("DD/MM/YYYY")
      state.project = props.currentSession.project || []
      state.start = moment(props.currentSession.start).format("HH:mm")
      state.end = moment(props.currentSession.end).format("HH:mm")
      state.tasks = props.currentSession && props.currentSession.tasks.length > 0 ? props.currentSession.tasks[0].desc : ""
      state.sick = props.currentSession.sick
      state.holiday = props.currentSession.holiday
    }
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  saveDesc(desc) {
    this.setState({ desc })
  }

  buttonUpdateSession = async () => {
    let session = {
      _id: this.props.currentSession._id,
      start: moment.utc(new Date(this.state.date.toString() + " " + this.state.start)).toISOString(),
      end: moment.utc(new Date(this.state.date.toString() + " " + this.state.end)).toISOString(),
      user: this.props.user._id,
      project: this.state.project,
      tasks: [{
        desc: this.state.desc,
        start: moment.utc(new Date(this.state.date.toString() + " " + this.state.start)).toISOString(),
        end: moment.utc(new Date(this.state.date.toString() + " " + this.state.end)).toISOString(),
        absent: false,
        break: false,
        attachment: null
      }],
      attachment: null,
      holiday: this.state.holiday,
      sick: this.state.sick
    }
    let resp = await this.props.updateSession(session)
    await this.props.getSessions(
      0,
      moment().set("hour", 23).set("minute", 59).add(10, "years").valueOf(),
      this.props.team._id
    )
    if (resp.error) alert("Ha habido un problema")
    else {
      this.props.history.push("/sessions")
    }
    this.closeModal()
  }

  render() {
    return (
      <div className="cell flex-10 action-cell">
        <div className="flex-row">
          <Button
            className="button-icon"
            onClick={() => {
              this.props.updateAction()
              this.openModal()
            }}
          >
            <i className="fa fa-edit "/>
          </Button>
          <Button
            className="button-icon"
            onClick={() => {
              this.props.deleteAction()
              this.props.getSessions(
                0,
                moment().set("hour", 23).set("minute", 59).add(10, "years").valueOf(),
                this.props.team._id
              )
            }}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <div className="enterModal card">
            <div className="card-header">
              <span>Session {this.state.date}</span>
              <Button className="btn-close" onClick={this.closeModal}><i className="fa fa-times"/></Button>
            </div>
            <div className="card-body">
              <div className="project-item">
                <Form className="custom-form">
                  <FormGroup>
                    <ControlLabel>Project</ControlLabel>
                    <FormControl
                      componentClass="select"
                      id="project"
                      placeholder={this.state.project.name}
                      onChange={evt => (this.setState( { project: evt.target.value }))} //eslint-disable-line
                    >
                      <option key={"no-project"} value={null}>Selecciona un proyecto </option>
                      {this.props.projects[0] !== null ?
                        this.props.projects.map((project, index) => ( //eslint-disable-line
                          <option
                            key={project._id}
                            value={project._id}
                            selected={this.state.project._id === project._id}
                          >
                            {project.name}
                          </option>))
                        : ""
                      }
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Description</ControlLabel>
                    <textarea
                      id="exampleFormControlTextarea1"
                      placeholder="Description task"
                      rows="3"
                      onChange={evt => this.saveDesc(evt.target.value)}
                      defaultValue={this.state.tasks || ""}
                    />
                  </FormGroup>
                  <FormGroup className="margin-left-110">
                    <div>
                      <ControlLabel>Start</ControlLabel>
                      <FormControl
                        id="periodstart"
                        onChange={evt =>( this.setState( { start: evt.target.value }))} //eslint-disable-line
                        type="time"
                        defaultValue={this.state.start}
                      />
                      <ControlLabel>End</ControlLabel>
                      <FormControl
                        id="periodend"
                        onChange={evt =>( this.setState( { end: evt.target.value }))} //eslint-disable-line
                        type="time"
                        defaultValue={this.state.end}
                      />
                    </div>
                  </FormGroup>
                  <div>
                    <Checkbox id="sick" onChange={() => this.setState( { sick: !this.state.sick })} defaultChecked={this.state.sick}>
                      Enfermo
                    </Checkbox>
                    <Checkbox id="holiday" onChange={() => this.setState( { holiday: !this.state.holiday })} defaultChecked={this.holiday}>
                      Vacaciones
                    </Checkbox>
                  </div>
                  <div className="margin-left-110 flex-row">
                    <Button className="add-btn" value=""><span>Add Period</span><i className="fa fa-plus-circle"></i></Button>
                  </div>

                  <div className="margin-left-110"><hr /></div>
                </Form>
              </div>

              <div className="margin-left-110"><button className="btn btn-secondary" value="Enviar"> ADD PROJECT</button></div>

              <ButtonGroup className="flex-row margin-left-110">
                <Button onClick={this.closeModal} color="primary" className="cancel">CANCEL</Button>
                <Button type="button" onClick={() => this.buttonUpdateSession()} color="primary">SAVE</Button>
              </ButtonGroup>
            </div>
          </div>

        </Modal>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    currentSession: state.users.currentSession,
    user: state.users.activeUser,
    projects: state.projects,
    team: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  updateSession,
  getSessions
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomModal))  //eslint-disable-line