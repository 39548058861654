import React from "react"
import {Button} from "react-bootstrap"
import { connect } from "react-redux"
import ModalUpdateCalendar from "./ModalUpdateCalendar"

const CalendarsListItem = ({ name, holidays, updateAction, stateModal }) => {
  let holidaysString = ""
  holidays.map(holiday => {
    return holidaysString += (holiday.day + "/" + holiday.month + " ")
  })
  return (

    <div className="calendar-header">
      <div className="calendar-name">{ name }</div>
      {/*<div>{"Vacaciones: " + holidaysString }</div>*/}
      <Button className="icon-button" onClick={updateAction}>
        <i className="fa fa-edit"/>
      </Button>
    </div>
  

  )
}

export default connect(
  null,
  null
)(CalendarsListItem)
