import { apiCreateWorkzone, apiUpdateWorkzone } from "../actions/api"

export default (state = [], { type, payload, ...action }) => {
  switch (type) {
  case apiCreateWorkzone.types.success: {
    if (Array.isArray(state)) {
      state.push(payload)
      return state}
    return [payload]
  }
  case apiUpdateWorkzone.types.success: {
    return { ...state, ...payload }
  }
  default:
    return state
  }
}
