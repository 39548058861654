import { Alert, Button, Form, FormControl, FormGroup } from "react-bootstrap"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"

class ProjectsList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      newName: ""
    }
  }

 close = (idx) => {
   this.props.onEditOrganisation(idx)
 }

 render() {
   return (
     <div>
       {!this.props.projects || this.props.projects === null || this.props.projects === undefined || this.props.projects.length === 0 ? //eslint-disable-line
         <Alert bsStyle="warning">
           <i className="fa fa-exclamation-triangle" />
           <div className="alert-content">
             <h4>No hay projectos definidos para este equipo</h4>
             <hr />
           </div>
         </Alert>
         :
         <div>
           {this.props.projects.map((project, idx) => (
             this.props.isEditing && this.props.editIdx === idx ?
               <div className="card flex-row edit-card" key={project._id}>
                 <i className="fa fa-briefcase" />
                 <Form className="mr-auto">
                   <FormGroup>
                     <FormControl
                       id="projectname"
                       onChange={evt => (this.setState({ newName: evt.target.value }))}
                       type="text"
                       placeholder="Nombre nuevo"
                     />
                   </FormGroup>
                 </Form>

                 <Button
                   disabled={this.state.newName.length < 1}
                   className="icon-button"
                   onClick={() => {
                     if (this.state.newName) project.name = this.state.newName
                     this.props.updateProject(
                       this.props.team,
                       project
                     )
                     this.close(idx)
                   }}
                 ><i className="fa fa-save"></i>
                 </Button>

                 <Button
                   className="icon-button"
                   onClick={() => {
                     this.close(idx)
                   }}
                 ><i className="fa fa-times-circle"></i>
                 </Button>
               </div>
               :
               <div className="card flex-row" key={project._id}>
                 <i className="fa fa-briefcase" />
                 <span className="project-name mr-auto">{project.name}</span>

                 <Button
                   onClick={() => this.props.onEditOrganisation(idx)}
                   className="icon-button"
                 >
                   <i className="fa fa-edit"></i>
                 </Button>
                 <Button
                   onClick={() => {
                     this.props.deleteProject(project.team, project._id)
                     this.props.getProjects(this.props.tid)
                   }} className="icon-button"
                 >
                   <i className="fa fa-trash" />
                 </Button>
               </div>))
           }
         </div>
       }
     </div>
   )
 }

}

export default withRouter(ProjectsList)