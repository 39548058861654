import React, { Component } from "react"
import CalendarsList from "./CalendarsList"

class CalendarsContainer extends Component {

  render() {
    return (
      <CalendarsList />
    )
  }

}

export default CalendarsContainer
