import { Button, FormControl } from "react-bootstrap"
import React from "react"

//<Form.Input name='username' placeholder='Username' />
export default (props) =>
  <li>
    <FormControl
      name="userorganisation"
      placeholder="Enter Organisation name..."
      type="text"
      id="teamName"
      onChange={props.onChange}
      value={props.text}
    />
    <Button
      disabled = {props.text.length < 2}
      className="icon-button large-icon"
      onClick={() => props.createTeam()}
    >
      <i className="fa fa-plus-circle"></i>
    </Button>
  </li>
