import { Button, Col, Form, FormControl, FormGroup, Grid, PageHeader, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import { auth, getUser } from "../../actions/api"
import { Component } from "react"
import React from "react"
import { connect } from "react-redux"
import { logOut } from "../../actions/logOutAction"
import logoProdit from "../../images/logoProdit.svg"

var sha512 = require("js-sha512").sha512

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: ""
    }
  }

  componentDidMount = () => {
    this.props.logOut()
  }

  onChangePassword = text => { this.setState({ password: text }) }
  onChangeEmail = text => { this.setState({ email: text }) }
  navigateResetPass = () => this.props.history.push("/reset_pass")
  navigateSignup = () => this.props.history.push("/register")

  handleLogin = async () => {
    var email = document.getElementsByClassName("form-control email")["email"].value
    var pass = document.getElementsByClassName("form-control password")["password"].value
    pass = sha512(pass)
    try {
      let resp = await this.props.auth(email, pass)
      if (resp.error) {
        alert("Ingrese sus credenciales correctas")
      } else {
        await this.props.getUser(resp.payload.id)
        this.props.history.push("/teams/select")
      }
    } catch (error) {
      alert("Error interno")
    }
  }

  render() {
    return (
      <Grid className="login-page">
        <Row className="center-content">
          <Col xs={12} md={10} lg={5}>
            <div className="logo-area">
              <h1><img className="logo-img" src={logoProdit} /></h1>
            </div>
            <PageHeader>TEAMS, PROJECTS AND SESSIONS MANAGEMENT</PageHeader>
          </Col>
        </Row>
        <Row className="center-content">
          <Col xs={12} md={10} lg={5}>
            <div className="card enterform">
              <div className="card-header">
                <div className="card-title">
                  <div className="brackets">
                    <span className="top-left"></span>
                    <span className="top-right"></span>
                    <span className="bottom-left"></span>
                    <span className="bottom-right"></span>
                  </div>
                  <span className="card-title-name">LOG<b> IN</b></span>
                </div>
              </div>
              <div className="card-body">
                <Form>
                  <FormGroup className="icon-input">
                    <i className="fa fa-envelope"></i>
                    <FormControl className="email" name="email" placeholder="Enter e-mail" type="text" />
                  </FormGroup>
                  <FormGroup className="icon-input">
                    <i className="fa fa-lock"></i>
                    <FormControl className="password" name="password" placeholder="Enter Password" type="password" />
                  </FormGroup>
                </Form>
                <Button bsStyle="primary" onClick={this.handleLogin} value="Enviar"> Log In</Button>
                <div className="flex-row">
                  <Link className="simple-link" to={"/reset_pass"}>Forgot password?</Link>
                  <Link className="simple-link ml-auto" to={"/register"}>Create account</Link>
                </div>
              </div>
              <div className="gradient-bottom" />
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }

}

const mapDispatchToProps = {
  auth,
  getUser,
  logOut
}

export default withRouter(connect(null, mapDispatchToProps)(Login))
