import React, { Component } from 'react'
import { Button, Image } from 'react-bootstrap';

class Member extends Component {

  state = {
    editing: false,
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    _id: ""
  }

  componentWillMount(){
    this.setUserToState()
  }

  // Set to state the user values
  setUserToState = () => {
    const { firstName, lastName, email, id, _id } = this.props.user
    let avatar = this.props.user.avatar

    // Default avatar if user doesn't have one
    if (avatar === undefined){
      avatar = "/thumbnail.png"
    }

    // Set in the state the member info
    this.setState({ firstName, lastName, email, avatar, id, _id })
  }

  saveMember = async () => {
    const { firstName, lastName, email } = this.state

    // if empty values, alert to user and break
    if (firstName === "" || lastName === "" || email === ""){
      alert("Por favor, rellene los campos requeridos")
      return null
    }

    // all data is correct
    let user = this.props.user
    
    // The updateMember function need the same member with updated values
    user.firstName = this.state.firstName
    user.lastName = this.state.lastName
    user.email = this.state.email

    await this.props.updateUser(user)

    // Close modify mode
    this.closeModifyMode()
  }

  // Close modifing mode without saving new state.
  closeModifyMode = () => {
    this.setUserToState()
    this.setState({ editing: false })
  }

  render() {
    return (
      <tr>
        <td>
          <Image src={this.state.avatar} circle />
        </td>
        <td>
          <input
            type="text"
            name="firstName"
            className="form-control form-changing py-0"
            value={this.state.firstName}
            readOnly={!this.state.editing} //If not editing... readonly
            onChange= {(evt) =>
              (this.setState({ firstName: evt.target.value }))
            }
          />
        </td>
        <td>
          <input
            type="text"
            name="lastName"
            className="form-control form-changing py-0"
            value={this.state.lastName}
            readOnly={!this.state.editing} //If not editing... readonly
            onChange= {(evt) =>
              (this.setState({ lastName: evt.target.value }))
            }
          />
        </td>
        <td>
          <input
            type="text"
            name="email"
            className="form-control form-changing py-0"
            value={this.state.email}
            readOnly={!this.state.editing} //If not editing... readonly
            onChange= {(evt) =>
              (this.setState({ email: evt.target.value }))
            }
          />
        </td>
        <td>
          { this.state.editing ? //the user is being updated -> save and close button
            <React.Fragment>
              <Button
                name="close-button"
                className="icon-button mx-2"
                onClick={this.closeModifyMode}
              >
                <i className="fa fa-close"></i>
              </Button>
              <Button
                name="save-button"
                className="icon-button mx-2"
                onClick={this.saveMember}
              >
                <i className="fa fa-save"></i>
              </Button>
            </React.Fragment>
            : // else -> edit user and delete user
            <React.Fragment>
              <Button
                name="edit-button"
                className="icon-button mx-2"
                onClick={() => this.setState({ editing: true })}
              >
                <i className="fa fa-edit"></i>
              </Button>
              <Button
                name="delete-button"
                className="icon-button mx-2"
                onClick={() => this.props.deleteMember(this.props.user)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </React.Fragment>
          }
        </td>
      </tr>
    )
  }

}

export default Member
