import { Button, ButtonToolbar, DropdownButton, Image, MenuItem, SplitButton } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import React, { Component } from "react"
import { getProjects, setCurrentTeam } from "../actions/api"
import { connect } from "react-redux"
import { logOut } from "../actions/logOutAction"
import logoProdit from "../images/logoProdit.svg"
import userAvatar from "../images/userAvatar.png"

class ComponentHeader extends Component {

  setTeamAndGetProjects(team) {
    this.props.setCurrentTeam(team)
    this.props.getProjects(team._id)
  }

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="page-header">
        <Button bsStyle="link" className="logo-btn mr-auto" onClick={() => this.props.history.push("/home")}>
          <Image src={logoProdit} />
        </Button>
        <div className="organisation-area">
          <div id="profileDropdown">
            <i className="fa fa-users"></i>
            <DropdownButton title={this.props.currentTeam.name}>
              {this.props.teams.map((team, index) => ( //eslint-disable-line
                <MenuItem key={team._id}>
                  <Link to={"/home"} onClick={async () => this.setTeamAndGetProjects(team)} className="team-link">
                    {team.name}
                  </Link>
                </MenuItem>))
              }
            </DropdownButton>
            <DropdownButton
              className="profile-btn"
              noCaret
              id="dropdownNoCaret"
              title={
                <Image
                  className="thumbnail-image"
                  src={userAvatar}
                  alt="user pic"
                />
              }
            >
              <Image className="user-pic" src={userAvatar}></Image>
              <span className="user-name">{this.props.user.firstName + " " + this.props.user.lastName}</span>
              <span className="user-role">UX Designer and Developer</span>
              <hr className="separator" />
              <span className="user-organisation">{this.props.currentTeam.name}</span>
              <hr className="bottom-separator"/>
              <Button onClick={() => {
                this.props.logOut()
                this.props.history.push("/")
              }}
              >
                <span>LOG OUT</span>
                <i className="fa fa-power-off" />
              </Button>
            </DropdownButton>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    projects: state.projects,
    teams: state.teams,
    currentTeam: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  setCurrentTeam,
  getProjects,
  logOut
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComponentHeader)) //eslint-disable-line
