import { ControlLabel, Form, FormControl, FormGroup, Grid } from "react-bootstrap"
import { createCalendar, getTeams, setCurrentTeam } from "../../actions/api"
import { Button } from "react-bootstrap"
import Modal from "react-modal"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

class ModalAddCalendar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: "",
      holidays: [{ day: "", month: "" }]
    }
  }

  onChangeDay = (evt, indexHoliday) => {
    const { holidays: holidays0 } = this.state
    let holidays = Object.assign([], holidays0)
    holidays[indexHoliday].day = evt.target.value
    this.setState({ holidays })
  }

  onChangeMonth = (evt, indexHoliday) => {
    const { holidays: holidays0 } = this.state
    let holidays = Object.assign([], holidays0)
    holidays[indexHoliday].month = evt.target.value
    this.setState({ holidays })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyles}
      >
        <Grid>
          <div className="card">
            <div className="card-header">
              <span>Nuevo calendario</span>
              <Button className="btn-close" onClick={this.props.close}><i className="fa fa-times" /></Button>
            </div>
            <div className="card-body">
              <Form className="custom-form">
                <FormGroup isRequired>
                  <ControlLabel>Nombre</ControlLabel>
                  <FormControl
                    placeholder="Nombre del calendario"
                    id="calendarName"
                    onChange={evt => (this.setState({ name: evt.target.value }))}
                    type="text"
                    value={this.name}
                  />
                </FormGroup>
                <div className="holidays-container">
                  <span className="fake-label">Vacaciones</span>
                  <div className="margin-left-110 add-holidays-container">
                    {this.state.holidays.map((holiday, index) => {
                      let dayId = "day" + index, monthId = "month" + index
                      return (
                        <div className="flex-row" key={dayId + monthId}>
                          <FormGroup >
                            <ControlLabel>Día</ControlLabel>
                            <FormControl
                              id={dayId}
                              onChange={(evt) => this.onChangeDay(evt, index)}
                              type="text"
                              defaultValue={holiday.day}
                            />
                            <ControlLabel>Mes</ControlLabel>
                            <FormControl
                              id={monthId}
                              onChange={(evt) => this.onChangeMonth(evt, index)} //eslint-disable-line
                              type="text"
                              defaultValue={holiday.month}
                            />
                          </FormGroup>
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
                <div className="flex-row margin-botton-20">
                  <span className="ml-auto fake-btn">Añadir/Eliminar festivo</span>
                  <Button
                    className="icon-button add-btn"
                    onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays, { day: "", month: "" }]
                      }))
                    }}
                  ><i className="fa fa-plus-circle"></i>
                  </Button>
                  <Button
                    className="icon-button add-btn"
                    onClick={() => {
                      this.setState((prevState) => ({
                        holidays: [...prevState.holidays.slice(0, prevState.holidays.length - 1)] //eslint-disable-line
                      }))
                    }}
                  ><i className="fa fa-minus-circle"></i>
                  </Button>
                </div>
                <div className="flex-row margin-left-110">
                  <Button bsStyle="primary" className="cancel" onClick={this.props.close}>Cancelar</Button>
                  <span className="separator" />
                  <Button
                    disabled={this.state.name.length === 0 || this.state.holidays[0].day.length === 0 || this.state.holidays[0].month.length === 0}
                    bsStyle="primary"
                    type="button"
                    onClick={async () => {
                      let calendar = {
                        name: this.state.name,
                        holidays: this.state.holidays
                      }
                      let resp = await this.props.createCalendar(this.props.team._id, calendar) //eslint-disable-line
                      if (resp.error) alert("Ha habido un problema")
                      else {
                        this.props.getTeams()
                        this.props.close()
                      }
                    }}
                  >Guardar</Button>
                </div>
              </Form>
            </div>
          </div>
        </Grid>
      </Modal>)
  }

}

const mapStateToProps = state => {
  return {
    team: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  createCalendar,
  setCurrentTeam,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalAddCalendar))
