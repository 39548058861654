import React from "react"
import { withRouter } from "react-router-dom"

const SetPass = (props) =>
  <div className="login">
    <span>E-mail: </span><input type="text" /><br />
    <span>New password: </span><input type="password" /><br />
    <span>Repeat password: </span><input type="password" /><br />
    <button onClick={() => { }}>Continue</button>
    <button onClick={() => props.history.goBack()}>Cancel</button>
  </div>

export default withRouter(SetPass)