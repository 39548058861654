import { Button, Checkbox, ControlLabel, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap"
import { createWorkzone, getTeams } from "../../actions/api"
import Modal from "react-modal"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "react-times/css/classic/default.css" //eslint-disable-line
import "react-times/css/material/default.css"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px"
  }
}

Modal.setAppElement(document.getElementById("root"))

class ModalNewWorkzone extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.holiday = false
    this.weekends = false
  }

  openModal() {
    this.setState({ modalIsOpen: true })
    this.holiday = false
    this.weekends = false
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  openTimeToObject = (openTime) => {
    let openHour = parseInt(openTime.slice(0, 2))
    let openMinute = parseInt(openTime.slice(3, 6))
    return { hour: openHour, minute: openMinute }
  }
  closeTimeToObject = (closeTime) => {
    let closeHour = parseInt(closeTime.slice(0, 2))
    let closeMinute = parseInt(closeTime.slice(3, 6))
    return { hour: closeHour, minute: closeMinute }
  }

  buttonAddWorkzone = async () => {
    //console.log(this.weekends)
    //debugger;
    let workzone = {
      name: this.name,
      gmt: this.gmt,
      openTime: this.open,
      closeTime: this.close,
      openOnWeekends: this.weekends,
      openOnHolidays: this.holiday,
      beacons: ""
    }
    let resp = await this.props.createWorkzone(this.props.team._id, workzone)
    if (resp.error) alert("Ha habido un problema")
    else {
      this.props.getTeams()
    }
    this.closeModal()
  }

  render() {
    return (
      <div>
        <div>
          <Button
            className="button-card"
            bsStyle="primary"
            onClick={() => { this.openModal() }}
          >
            <span>Añadir zona de trabajo</span>
            <i className="fa fa-plus-circle" />
          </Button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <Grid>
            <div className="enterModal card">
              <div className="card-header">
                <span>Nueva zona de trabajo</span>
                <Button className="btn-close" onClick={this.closeModal}><i className="fa fa-times" /></Button>
              </div>
              <div className="card-body">
                <Form className="custom-form">
                  <div>
                    <FormGroup>
                      <ControlLabel>Nombre</ControlLabel>
                      <FormControl
                        type="text"
                        placeholder="Nombre"
                        onChange={evt => (this.name = evt.target.value)}
                        defaultValue=""
                      >
                      </FormControl>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Zona horaria</ControlLabel>
                      <FormControl
                        id="gmt"
                        type="number"
                        onChange={evt => (this.gmt = evt.target.value)}
                      >
                      </FormControl>
                    </FormGroup>
                    <div className="form-inline-container">
                      <span className="fake-label">Horario</span>
                      <div className="margin-left-110 flex-form-line">
                        <div className="flex-row">
                          <FormGroup>
                            <ControlLabel>Abre</ControlLabel>
                            <FormControl
                              id="openhour"
                              onChange={evt => (this.open = this.openTimeToObject(evt.target.value))}
                              type="time"
                            >
                            </FormControl>
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Cierra</ControlLabel>
                            <FormControl
                              id="closehour"
                              onChange={evt => (this.close = this.closeTimeToObject(evt.target.value))}
                              type="time"
                            >
                            </FormControl>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <FormGroup className="custom-checkbox-container margin-left-110">
                      <Checkbox
                        className="custom-checkbox"
                        id="weekends"
                        defaultChecked={this.weekends}
                        onClick={() => this.weekends = !this.weekends}
                      >
                        <div className="control__indicator"></div>
                      </Checkbox>
                      <ControlLabel>
                        Abierto los fines de semana
                      </ControlLabel>
                    </FormGroup>
                    <FormGroup className="custom-checkbox-container margin-left-110">
                      <Checkbox
                        className="custom-checkbox"
                        id="holiday"
                        defaultChecked={this.holiday}
                        onClick={() => this.holiday = !this.holiday}
                      >
                        <div className="control__indicator"></div>
                      </Checkbox>
                      <ControlLabel>Abierto en vacaciones</ControlLabel>
                    </FormGroup>
                  </div>
                </Form>
                <div className="flex-row margin-left-110">
                  <Button onClick={this.closeModal} bsStyle="primary" className="cancel">Cancelar</Button>
                  <span className="separator" />
                  <Button type="button" onClick={this.buttonAddWorkzone} bsStyle="primary">Guardar</Button>
                </div>
              </div>
            </div>
          </Grid>
        </Modal>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    team: state.users.currentTeam,
    projects: state.projects
  }
}

const mapDispatchToProps = {
  createWorkzone,
  getTeams
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalNewWorkzone)) //eslint-disable-line

