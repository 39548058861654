import { Button, Form, FormControl, FormGroup } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import React from "react"

class TeamsList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  close = (idx) => {
    this.props.onEditOrganisation(idx)
  }

  render() {
    return (
      <ul className="teamList">
        {this.props.teams.map((team, idx) => (

          this.props.isEditing && this.props.editIdx === idx ?

            <li className="list-item-focus" key={team._id}>
              <div className="flex-row">
                <Form>
                  <FormGroup>
                    <FormControl
                      id="teamname"
                      onChange={evt => (this.newName = evt.target.value)}
                      type="text"
                      placeholder="Nombre nuevo"
                    />
                  </FormGroup>
                </Form>

                <Button
                  className="icon-button"
                  onClick={() => {
                    if (this.newName) team.name = this.newName
                    this.props.updateTeam(team)
                    this.close(idx)
                  }}
                ><i className="fa fa-save"></i>
                </Button>

                <Button
                  className="icon-button"
                  onClick={() => {
                    this.close(idx)
                  }}
                ><i className="fa fa-times-circle"></i>
                </Button>

              </div>
            </li> :

            <li key={team._id}>
              <div className="flex-row">
                <Link to={"/home"} onClick={async () => this.props.setCurrentTeam(team)} className="team-link">
                  {team.name}
                </Link>
                <Button
                  onClick={() => this.props.onEditOrganisation(idx)}
                  className="icon-button"
                >
                  <i className="fa fa-edit"></i>
                </Button>
                <Button
                  onClick={() => this.props.deleteTeam(team._id)}
                  className="icon-button"
                >
                  <i className="fa fa-trash" />
                </Button>
              </div>
            </li>

        ))}
      </ul>
    )
  }

}

export default withRouter(TeamsList)
