import {
  Button,
  ControlLabel,
  Form,
  FormControl
} from "react-bootstrap"
import React from "react"
import { connect } from "react-redux"
import { updateTeam } from "../../actions/api"
import { withRouter } from "react-router-dom"

class EditTeam extends React.Component{

  render(){
    let teamName = this.props.currentTeam.name
    return (
      <div>
        <Form>
          <ControlLabel>Nuevo nombre</ControlLabel>
          <FormControl
            id="teamname"
            onChange={evt => (this.newName = evt.target.value)}
            type="text"
            defaultValue={teamName}
          />
          <Button onClick={()=>{
            if (this.newName) this.props.currentTeam.name = this.newName
            let team = this.props.currentTeam
            this.props.updateTeam(team)
            this.props.history.goBack()}}
          >Actualizar nombre
          </Button>
          <Button onClick={() => this.props.history.push("/teams/select")}>Cancelar</Button>
        </Form>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    currentTeam: state.users.currentTeam,
    user: state.users.activeUser
  }
}

const mapDispatchToProps = {
  updateTeam
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTeam))

