import React, { Component } from "react"
import SessionsTable from "../Sessions/SessionsTable"
import { connect } from "react-redux"
import { getSessions } from "../../actions/api"
import moment from "moment"
import { withRouter } from "react-router-dom"

class SessionsContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      startDefault: 0,
      endDefault: moment().set("hour", 23).set("minute", 59).add(10, "years").valueOf()
    }
  }

  componentDidMount() {
    try {
      this.props.getSessions(
        this.state.startDefault,
        this.state.endDefault,
        this.props.team._id
      )
    } catch (error){
      alert("Sus sesiones no pudieron ser cargadas")
    }
  }

  render() {
    return (
      <div>
        <SessionsTable />
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    auth: state.users,
    sessions: state.sessions || [],
    team: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  getSessions
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SessionsContainer))
