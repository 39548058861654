import Login from "./Auth/Login"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class LandingPage extends React.Component {

  render() {
    return (
      <div className="home-page">
        <Login />
      </div>
    )
  }

}

const mapStateToProps = (state, props) => ({ //eslint-disable-line
  auth: state.users,
  user: state.users.activeUser || ""
})

export default withRouter(connect(mapStateToProps, null)(LandingPage))
