import "./stylesheets/main.css"
import { BrowserRouter, Route } from "react-router-dom"
import AddMembers from "./components/Organizations/AddMembers"
import CalendarsContainer from "./components/Calendars/CalendarsContainer"
import CalendarsListView from "./components/Calendars/CalendarsListView"
import EditTeam from "./components/Organizations/EditTeam"
import EditWorkzone from "./components/Workzones/EditWorkzone"
import Home from "./components/Home"
import LandingPage from "./components/LandingPage"
import Login from "./components/Auth/Login"
import MembersList from "./components/Organizations/MembersList"
import NewCalendar from "./components/Calendars/NewCalendar"
import NewSession from "./components/Sessions/NewSession"
import NewTeamContainer from "./components/Organizations/NewTeamContainer"
import NewWorkzone from "./components/Workzones/NewWorkzone"
import ProjectsContainer from "./components/Projects/ProjectsContainer"
import { Provider } from "react-redux"
import React from "react"
import Register from "./components/Auth/Register"
import Reset from "./components/Auth/ResetPass"
import SessionsContainer from "./components/Sessions/SessionsContainer"
import SessionsTable from "./components/Sessions/SessionsTable"
import SetPass from "./components/Auth/SetPass"
import TeamsContainer from "./components/Organizations/TeamsContainer"
import UpdateCalendar from "./components/Calendars/UpdateCalendar"
import UpdateSession from "./components/Sessions/UpdateSession"
import WorkzonesList from "./components/Workzones/WorkzonesList"
import { configureStore } from "./store/configureStore"
import "tabler-react/dist/Tabler.css" // eslint-disable-line
import '@trendmicro/react-sidenav/dist/react-sidenav.css' // eslint-disable-line
import { PersistGate } from "redux-persist/integration/react"

const store = configureStore()

export default () => (
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <BrowserRouter>
        <div>
          <Route exact path ="/" component={LandingPage}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset_pass" component={Reset} />
          <Route exact path="/set_pass" component={SetPass} />
          <Route exact path="/teams/select" component={TeamsContainer} />
          <Route exact path="/team/edit" component={EditTeam} />
          <Route exact path="/team/new" component={NewTeamContainer} />
          <Route exact path="/sessions" component={SessionsContainer} />
          <Route exact path="/sessions/new" component={NewSession} />
          <Route exact path="/sessions/update" component={UpdateSession} />
          <Route exact path="/projects" component={ProjectsContainer} />
          <Route exact path="/sessions/table" component={SessionsTable} />
          <Route exact path="/calendars" component={CalendarsContainer} />
          <Route exact path="/calendars/new" component={NewCalendar} />
          <Route exact path="/calendars/update" component={UpdateCalendar} />
          <Route exact path="/calendars/list" component={CalendarsListView} />
          <Route exact path="/members" component={MembersList} />
          <Route exact path="/members/new" component={AddMembers} />
          <Route exact path="/workzones" component={WorkzonesList} />
          <Route exact path="/workzones/new" component={NewWorkzone} />
          <Route exact path="/workzone/edit" component={EditWorkzone} />
        </div>
      </BrowserRouter>
    </PersistGate>
  </Provider>
)
