import { Col, Row, Table } from "react-bootstrap"
import { getProjects, getSessions } from "../actions/api"
import ComponentHeader from "./ComponentHeader"
import ComponentSaveNav from "./ComponentSaveNav"
import React from "react"
import chartImg from "../images/chart.jpg"
import { connect } from "react-redux"
import moment from "moment"
import { withRouter } from "react-router-dom"

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      lastSession: "",
      startDefault: 0,
      endDefault: moment().set("hour", 23).set("minute", 59).add(10, "years").valueOf()
    }
  }

  componentDidMount() {
    try {
      this.props.getSessions(
        this.state.startDefault,
        this.state.endDefault,
        this.props.team._id
      )
    } catch (error) {
      alert("Sus sesiones no pudieron ser cargadas")
    }
    this.project = ""
    const teamId = this.props.team._id
    this.props.getProjects(teamId)
  }

  componentDidUpdate(prevProps) {
    const teamId = this.props.team._id
    if (prevProps.projects.length !== this.props.projects.length) {
      this.props.getProjects(teamId)
    }
  }

  render() {
    return (

      <div className="dashboard-page" id="homePage">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav />

          <div className="main-content-area">
            <h2 className="section-title">
              <b>Bienvenido/a:</b> {this.props.user.firstName + " " + this.props.user.lastName}
            </h2>

            <Row className="banner-row">
              <Col md={4}>
                <div className="card color-mid-green">
                  <div className="banner-icon"> <i className="fe fe-clock"></i></div>
                  <div className="banner-content">
                    <span className="banner-description">Última Sesion:</span>
                    <hr />
                    <span className="banner-data">
                      {this.props.sessions[0] !== null ? (
                        this.setState.lastSession = this.props.sessions[this.props.sessions.length - 1], //eslint-disable-line
                        moment(this.state.lastSession.start).format("DD/MM/YYYY")
                      )
                        :

                        <div className="empty-state">
                          <span>No hay sesiones registradas</span>
                        </div>
                      }
                    </span>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="card color-light-green">
                  <div className="banner-icon"><i className="fa fa-suitcase"></i></div>
                  <div className="banner-content">
                    <span className="banner-description">Proyectos asignados:</span>
                    <hr />
                    <span className="banner-data">
                      {this.props.projects.length}
                    </span>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className="card color-orange">
                  <div className="banner-icon"><i className="fa fa-bell"></i></div>
                  <div className="banner-content">
                    <span className="banner-description">Notificaciones sin leer:</span>
                    <hr />
                    <span className="banner-data">3</span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <div className="card">
                  <div className="card-header">Últimas sesiones</div>
                  <span className="border-header"></span>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Sesiones</th>
                        <th>Projectos</th>
                        <th>Principio/Fin</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.props.sessions !== null && this.props.sessions !== undefined && this.props.sessions.length !== 0) ? //eslint-disable-line
                        this.props.sessions.map(session => (
                          <tr key={session._id}>
                            <td>{moment(session.start).format("DD/MM/YYYY")}</td>
                            {session.project ?
                              <td><span>{session.project.name}</span></td>
                              : <td><span></span></td>
                            }
                            <td><span>{moment(session.start).format("h:mm:ss")}-{moment(session.end).format("h:mm:ss")}</span></td>
                          </tr>
                        ))
                        :

                        <div className="empty-state table-alert">
                          <span>No hay sesiones registradas</span>
                        </div>

                      }
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col md={4}>
                <div className="card">
                  <div className="card-header">Últimas sesiones</div>
                  <span className="border-header"></span>
                  <div className="hightcharts-frame"><img src={chartImg} alt="Chart" /></div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <div className="card">
                  <div className="card-header">PROYECTOS A LOS QUE PERTENECE</div>
                  <span className="border-header"></span>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Projecto</th>
                        <th>Cargo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {(this.props.projects !== null && this.props.projects !== undefined && this.props.projects.length !== 0) ? //eslint-disable-line
                        this.props.projects.map(project => (
                          <tr key={project._id}>
                            <td>{project.name}</td>
                            <td>Cargo</td>
                          </tr>
                        ))
                        :

                          <div className="empty-state table-alert">
                            <span>No hay projectos registrados</span>
                          </div>
                      }
                    </tbody>
                  </Table>
                </div>
              </Col>

              <Col md={6}>
                <div className="card" id="notifications-dashboard">
                  <div className="card-header">Últimas notificaciones</div>
                  <span className="border-header"></span>

                  <Table responsive>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Fecha</th>
                        <th>Proyecto</th>
                        <th>Descripcion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="alert-item"><i className="fa fa-bell" /></td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                      </tr>
                      <tr>
                        <td className="alert-item"><i className="fa fa-bell" /></td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                      </tr>
                      <tr>
                        <td><i className="fa fa-bell" /></td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                      </tr>
                      <tr>
                        <td><i className="fa fa-bell" /></td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                      </tr>
                      <tr>
                        <td><i className="fa fa-bell" /></td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                        <td>Table cell</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state, props) => ({ //eslint-disable-line
  auth: state.users,
  user: state.users.activeUser || "",
  team: state.users.currentTeam,
  projects: state.projects,
  sessions: state.sessions || [],
  teams: state.teams
})

const mapDispatchToProps = {
  getSessions,
  getProjects
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
