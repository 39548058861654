
import { Alert, Button, ButtonGroup, Checkbox, ControlLabel, Form, FormControl, FormGroup } from "react-bootstrap"
import Modal from "react-modal"
import React from "react"
import "react-times/css/classic/default.css" //eslint-disable-line
import "react-times/css/material/default.css"
import { Link, withRouter } from "react-router-dom"
import { createSession, getSessions } from "../actions/api"
import { connect } from "react-redux"
import moment from "moment"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
}

Modal.setAppElement(document.getElementById("root"))

class ModalNewSession extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.sick = false
    this.holiday = false
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  buttonAddSession = async () => {
    let session = {
      start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
      end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
      user: this.props.user._id,
      project: this.project,
      tasks: [{
        desc: this.tasks,
        start: moment.utc(new Date(this.date.toString() + " " + this.start)).toISOString(),
        end: moment.utc(new Date(this.date.toString() + " " + this.end)).toISOString(),
        absent: false,
        break: false,
        attachment: null
      }],
      attachment: null,
      holiday: this.holiday,
      sick: this.sick
    }
    let resp = await this.props.createSession(session)
    await this.props.getSessions(
      0,
      moment().set("hour", 23).set("minute", 59).add(10, "years").valueOf(),
      this.props.team._id
    )
    if (resp.error) alert("Ha habido un problema")
    else {
      this.props.history.push("/sessions")
    }
    this.closeModal()
  }

  saveDesc(desc) {
    this.setState({ desc })
  }

  render() {
    return (
      <div className="cell flex-10 action-cell">
        <div className="flex-row">
          <Button
            className="add-btn ml-auto"
            onClick={() => {
              this.openModal()
            }}
          >
            <i className="fa fa-plus-circle"/>
          </Button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Modal"
        >
          <div className="enterModal card">
            <div className="card-header">
              <span>Session</span>
              <Button className="btn-close" onClick={this.closeModal}><i className="fa fa-times"/></Button>
            </div>
            <div className="card-body">
              <div className="project-item">
                <h3 className="section-title">SESSIONS. Crear nueva sesión </h3>
                <Form className="custom-form">
                  {
                    !this.props.projects[0] ?
                      (
                        <Alert type="danger" icon="alert-triangle">
                          <div className="alert-content">
                            <span>No existen proyectos</span>
                            <hr />
                            <Link to="/projects">Por favor, crea tu nuevo proyecto<i className="fa fa-plus-circle" /></Link>
                          </div>
                        </Alert>
                      )
                      :
                      <div>
                        <FormGroup isRequired>
                          <ControlLabel> Fecha </ControlLabel>
                          <FormControl
                            id="date"
                            onChange={evt => (this.date = evt.target.value)}
                            type="date"
                          >
                          </FormControl>
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Project</ControlLabel>
                          <FormControl
                            componentClass="select"
                            id="project"
                            placeholder="Selecciona un proyecto"
                            onChange={evt => (this.project = evt.target.value)}
                          >
                            <option key={"no-project"} value={null}>Selecciona un proyecto</option>
                            {this.props.projects.map( project =>
                              <option key={project._id} value={project._id}>{project.name}</option> //eslint-disable-line
                            )}
                          </FormControl>
                        </FormGroup>
                        <FormGroup>
                          <ControlLabel>Description</ControlLabel>
                          <textarea
                            id="exampleFormControlTextarea1"
                            placeholder="Description task"
                            onChange={evt => this.tasks = evt.target.value}
                          >
                          </textarea>
                        </FormGroup>
                        <FormGroup className="margin-left-110">
                          <div>
                            <ControlLabel>Start</ControlLabel>
                            <FormControl
                              id="periodstart"
                              onChange={evt => ( this.start= evt.target.value )} //eslint-disable-line
                              type="time"
                            />
                            <ControlLabel>End</ControlLabel>
                            <FormControl
                              id="periodend"
                              onChange={evt =>( this.end= evt.target.value )} //eslint-disable-line
                              type="time"
                            />
                          </div>
                        </FormGroup>
                        <div>
                          <Checkbox id="sick" onChange={() => this.sick = !this.sick} defaultChecked={this.sick}>
                            Enfermo
                          </Checkbox>
                          <Checkbox id="holiday" onChange={() => this.holiday = !this.state.holiday} defaultChecked={this.holiday}>
                            Vacaciones
                          </Checkbox>
                        </div>
                        <div className="margin-left-110 flex-row">
                          <Button className="add-btn" value=""><span>Add Period</span><i className="fa fa-plus-circle"></i></Button>
                        </div>
                        <div className="margin-left-110"><hr /></div>
                      </div>
                  }
                </Form>
              </div>
              <ButtonGroup className="flex-row margin-left-110">
                <Button onClick={this.closeModal} color="primary" className="cancel">CANCEL</Button>
                <Button type="button" onClick={() => this.buttonAddSession()} color="primary">SAVE</Button>
              </ButtonGroup>
            </div>
          </div>
        </Modal>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    projects: state.projects,
    team: state.users.currentTeam
  }
}

const mapDispatchToProps = {
  createSession,
  getSessions
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalNewSession))  //eslint-disable-line