import { apiGetDetections } from "../actions/api";
export default (state = [], { type, payload, ...action }) => {
  switch (type) {
    case apiGetDetections.types.success: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
