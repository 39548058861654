
import {
  deleteTeam,
  getProjects,
  getTeams,
  inviteTeamMember,
  setCurrentTeam,
  updateTeam
} from "../../actions/api"
import { Grid, Row, Col, PageHeader} from "react-bootstrap"
import NewTeamContainer from "./NewTeamContainer"
import React from "react"
import TeamsList from "../Organizations/TeamsList"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import logoProdit from "../../images/logoProdit.svg"


class TeamsContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      editIdx: null
    }
  }

  navigateBack = () => this.props.history.goBack()

  setTeamAndGetProjects(team) {
    this.props.setCurrentTeam(team)
    this.props.getProjects(team._id)
  }

  componentDidMount() {
    if (this.props.teams.length === 0) {
      this.props.getTeams()
    }
  }

  onEditOrganisation = (idx) => {
    this.setState({ editing: !this.state.editing, editIdx: idx })
  }

  render() {
    return (
      <Grid className="login-page">
        <Row className="center-content">
          <Col md={10} lg={5}>
            <div className="logo-area">
              <h1><img className="logo-img" src={logoProdit} /></h1>
            </div>
            <PageHeader>
            "TEAMS, PROJECTS and SESSIONS MANAGEMENT"
            </PageHeader>
          </Col>
        </Row>
        <Row className="center-content">
          <Col md={10} lg={5}>
          <div className="card enterform">
              <div className="card-header">
                <div className="card-title">
                  <div className="brackets">
                    <span className="top-left"></span>
                    <span className="top-right"></span>
                    <span className="bottom-left"></span>
                    <span className="bottom-right"></span>
                  </div>
                  <span className="card-title-name">SIGN<b> UP</b></span>
                </div>
              </div>


              <div className="card-body">
                <ul className="organisation-list">
                  <TeamsList
                    teams={this.props.teams}
                    setCurrentTeam={(team) => this.setTeamAndGetProjects(team)}
                    deleteTeam={(tid) => this.props.deleteTeam(tid)}
                    updateTeam={(team) => this.props.updateTeam(team)}
                    onEditOrganisation={this.onEditOrganisation}
                    editIdx={this.state.editIdx}
                    isEditing={this.state.editing}
                  />
                  <NewTeamContainer />
                </ul>
                <div className="flex-row">
                  {/* <Button
                        className="simple-link"
                        onClick={this.navigateBack}>
                        <Icon prefix="fa" name="chevron-circle-left"></Icon>
                        Back
                      </Button> */}
                </div>
              </div>


              <div className="gradient-bottom" />
            </div>
          </Col>
        </Row>
      </Grid>
    )
  }

}
const mapStateToProps = state => {
  return {
    teams: state.teams || []
  }
}

const mapDispatchToProps = {
  getTeams,
  updateTeam,
  deleteTeam,
  setCurrentTeam,
  inviteTeamMember,
  getProjects
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamsContainer))
