import { Button, Checkbox, ControlLabel, Form, FormControl, FormGroup, Grid, Row } from "react-bootstrap"
import { getTeams, setCurrentWorkzone, updateWorkzone } from "../../actions/api"
import Modal from "react-modal"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "react-times/css/classic/default.css" //eslint-disable-line
import "react-times/css/material/default.css"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px"
  }
}

Modal.setAppElement(document.getElementById("root"))

class ModalEditWorkzone extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      name: "",
      gmt: "",
      openOnHolidays: false,
      openOnWeekends: false,
      closeTime: { hour: "0", minute: "0" },
      openTime: { hour: "0", minute: "0" }
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.buttonEditWorkzone = this.buttonEditWorkzone.bind(this)
  }

  componentDidMount() {
    this.setState({ name: this.props.currentWorkzone.name })
    this.setState({ gmt: this.props.currentWorkzone.gmt })
    this.setState({ openOnHolidays: this.props.currentWorkzone.openOnHolidays })
    this.setState({ openOnWeekends: this.props.currentWorkzone.openOnWeekends })
    this.setState({ closeTime: this.props.currentWorkzone.closeTime })
    this.setState({ openTime: this.props.currentWorkzone.openTime })
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  openTime = () => {
    let openMinute
    if (this.state.openTime.minute != null) {
      this.state.openTime.minute.toString().length === 1 ?
        openMinute = "0" + this.state.openTime.minute.toString() :
        openMinute = this.state.openTime.minute.toString()
    }
    let openHour
    if (this.state.openTime.hour != null) {
      this.state.openTime.hour.toString().length === 1 ?
        openHour = "0" + this.state.openTime.hour.toString() :
        openHour = this.state.openTime.hour.toString()
    }
    return (openHour + ":" + openMinute)
  }

  closeTime = () => {
    let closeMinute
    if (this.state.closeTime.minute != null) {
      this.state.closeTime.minute.toString().length === 1 ?
        closeMinute = "0" + this.state.closeTime.minute.toString() :
        closeMinute = this.state.closeTime.minute.toString()
    }
    let closeHour
    if (this.state.closeTime.hour != null) {
      this.state.closeTime.hour.toString().length === 1 ?
        closeHour = "0" + this.state.closeTime.hour.toString() :
        closeHour = this.state.closeTime.hour.toString()
    }
    return (closeHour + ":" + closeMinute)
  }
  openTimeToObject = (openTime) => {
    let openHour = parseInt(openTime.slice(0, 2))
    let openMinute = parseInt(openTime.slice(3, 6))
    return { hour: openHour, minute: openMinute }
  }
  closeTimeToObject = (closeTime) => {
    let closeHour = parseInt(closeTime.slice(0, 2))
    let closeMinute = parseInt(closeTime.slice(3, 6))
    return { hour: closeHour, minute: closeMinute }
  }

  buttonEditWorkzone = async () => {
    let workzone = {
      _id: this.props.currentWorkzone._id,
      name: this.state.name,
      gmt: this.state.gmt,
      openTime: this.state.openTime,
      closeTime: this.state.closeTime,
      openOnWeekends: this.state.openOnWeekends,
      openOnHolidays: this.state.openOnHolidays,
      beacons: ""
    }
    let resp = await this.props.updateWorkzone(
      this.props.team._id,
      workzone
    )
    if (resp.error) alert("Ha habido un problema")
    else {
      this.props.getTeams()
    }

    this.closeModal()
  }

  render() {
    return (
      <div>
        <div>
          <Button
            className="icon-button"
            onClick={() => { this.openModal() }}
          >
            <i className="fa fa-edit" />
          </Button>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Modal"
          style={customStyles}
        >
          <Grid>
            <div className="enterModal card">
              <div className="card-header">
                <span>Editar zona de trabajo</span>
                <Button className="btn-close" onClick={this.closeModal}><i className="fa fa-times" /></Button>
              </div>
              <div className="card-body">
                <Form className="custom-form">
                  <div>
                    <FormGroup>
                      <ControlLabel>Nombre</ControlLabel>
                      <FormControl
                        id="nombre"
                        onChange={evt => {
                          this.setState({ name: evt.target.value })
                        }}
                        type="text"
                        defaultValue={this.props.currentWorkzone.name}
                      >
                      </FormControl>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Zona horaria</ControlLabel>
                      <FormControl
                        id="gmt"
                        onChange={evt => (this.setState({ gmt: evt.target.value }))}
                        type="number"
                        defaultValue={this.props.currentWorkzone.gmt}
                      >
                      </FormControl>
                    </FormGroup>
                    <div className="form-inline-container">
                      <span className="fake-label">Horario</span>
                      <div className="margin-left-110 flex-form-line">
                        <div className="flex-row">
                          <FormGroup>
                            <ControlLabel>Abre</ControlLabel>
                            <FormControl
                              id="openhour"
                              onChange={evt => (this.setState({ openTime: this.openTimeToObject(evt.target.value) }))}
                              placeholder="Hora"
                              type="time"
                              defaultValue={this.openTime()}
                            >
                            </FormControl>
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Cierra</ControlLabel>
                            <FormControl
                              id="closehour"
                              onChange={evt => (this.setState({ closeTime: this.closeTimeToObject(evt.target.value) }))}
                              placeholder="Hora"
                              type="time"
                              defaultValue={this.closeTime()}
                            >
                            </FormControl>
                          </FormGroup>
                        </div>
                      </div>

                    </div>
                    <FormGroup className="custom-checkbox-container margin-left-110">
                      <Checkbox
                        className="custom-checkbox"
                        id="weekends"
                        defaultChecked={this.props.currentWorkzone.openOnWeekends}
                        onClick={() => this.setState({ openOnWeekends: !this.state.openOnWeekends })}
                      >
                        <div className="control__indicator"></div>
                      </Checkbox>
                      <ControlLabel>
                        Abierto los fines de semana
                      </ControlLabel>
                    </FormGroup>
                    <FormGroup className="custom-checkbox-container margin-left-110">
                      <Checkbox
                        className="custom-checkbox"
                        id="holiday"
                        defaultChecked={this.props.currentWorkzone.openOnHolidays}
                        onClick={() => this.setState({ openOnHolidays: !this.state.openOnHolidays })}
                      >
                        <div className="control__indicator"></div>
                      </Checkbox>
                      <ControlLabel>Abierto en vacaciones</ControlLabel>
                    </FormGroup>
                  </div>
                </Form>
                <div className="flex-row margin-left-110">
                  <Button onClick={this.closeModal} bsStyle="primary" className="cancel">Cancelar</Button>
                  <span className="separator" />
                  <Button type="button" onClick={this.buttonEditWorkzone} bsStyle="primary">Guardar</Button>
                </div>
              </div>
            </div>
          </Grid>
        </Modal>
      </div>
    )
  }

}
const mapStateToProps = state => {
  return {
    user: state.users.activeUser,
    team: state.users.currentTeam,
    projects: state.projects,
    teams: state.teams.list
  }
}

const mapDispatchToProps = {
  updateWorkzone,
  getTeams,
  setCurrentWorkzone
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalEditWorkzone)) //eslint-disable-line
