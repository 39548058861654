import { Button } from "tabler-react"
import React from "react"
import { connect } from "react-redux"
import { logOut } from "../../actions/logOutAction"
import { withRouter } from "react-router-dom"

class LogOut extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      holidays: [{ day: "", month: "" }]
    }
  }

  render() {
    return (
      <Button onClick={() => {
        this.props.logOut()
        this.props.history.push("/")
      }}
      >
      Log out
      </Button>

    )
  }

}

const mapDispatchToProps = { logOut }

export default withRouter(connect(null, mapDispatchToProps)(LogOut))
