
import { Button, Col, FormControl, FormGroup, Row } from "react-bootstrap"
import React, { Component } from "react"
import { createProject, deleteProject, getProjects, updateProject } from "../../actions/api"
import ComponentHeader from "../ComponentHeader"
import ComponentSaveNav from "../ComponentSaveNav"
import ProjectsList from "../Projects/ProjectsList"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

class ProjectsContainer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      newProject: false,
      editing: false,
      editIdx: null,
      project: ""
    }
  }

  componentDidMount() {
    const teamId = this.props.team._id
    this.props.getProjects(teamId)
  }

  componentDidUpdate(prevProps) {
    const teamId = this.props.team._id
    if (prevProps.projects.length !== this.props.projects.length) {
      this.props.getProjects(teamId)
    }
  }

  handleOnClick = () => {
    const newProject = {
      name: this.state.project,
      team: this.props.team._id,
      members: [{
        user: this.props.user._id,
        isAdmin: true, accepted: true
      }]
    }
    this.props.createProject(this.props.team._id, newProject) //eslint-disable-line
    this.setState({ newProject: false })
  }

  onEditOrganisation = (idx) => {
    this.setState({ editing: !this.state.editing, editIdx: idx })
  }

  render() {
    return (
      <div className="page dashboard-page" id="projectsPage">
        <ComponentHeader></ComponentHeader>
        <div className="page-main">
          <ComponentSaveNav />
          <div className="main-content-area">

            <h2 className="section-title">PROJECTS</h2>

            <Row className="justify-center margin-botton-20">
              <Col md={9} lg={6}>

                <ProjectsList
                  projects={this.props.projects}
                  deleteProject={this.props.deleteProject}
                  getProjects={this.props.getProjects}
                  updateProject={this.props.updateProject}
                  onEditOrganisation={this.onEditOrganisation}
                  editIdx={this.state.editIdx}
                  isEditing={this.state.editing}
                  team={this.props.team}
                />

                {this.state.newProject && <div className="card flex-row edit-card">{getProjects}
                  <i className="fa fa-briefcase" />
                  <FormGroup id="projectName" type="text">
                    <FormControl
                      className="project"
                      name="project"
                      onChange={evt => {
                        this.setState({ project: evt.target.value })
                      }}
                      placeholder="Introduce nombre del proyecto..."
                      type="text"
                    />
                  </FormGroup>

                  <Button
                    disabled={this.state.project.length < 1}
                    className="icon-button"
                    onClick={this.handleOnClick}
                  ><i className="fa fa-save"></i>
                  </Button>
                  <Button className="icon-button" onClick={() => this.setState({ newProject: false })} ><i className="fa fa-times-circle"></i> </Button>
                </div>}

                {!this.state.newProject &&
                  <div className="flex-row">
                    <Button className="button-card" bsStyle="primary" onClick={() => this.setState({ newProject: true })}>
                      <span>Añadir nuevo proyecto</span><i className="fa fa-plus-circle"></i>
                    </Button>
                  </div>
                }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  projects: state.projects || [],
  user: state.users.activeUser,
  team: state.users.currentTeam

})
const mapDispatchToProps = {
  createProject,
  getProjects,
  deleteProject,
  updateProject
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProjectsContainer)
)
